import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SectionContent } from '@careerhub/content-shared';
import { useCallback, useEffect } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sectionImageDeleteAsync } from '../../../logic/features/sections/section.actions';
import { Icon } from '../shared/Icon';

interface Props {
    name: string;
    sectionId: string;
    className?: string;
    onChange?: () => void;
    initialValue?: SectionContent['image'];
}

export const ImageControl: React.FC<Props> = ({
    name,
    sectionId,
    className,
    onChange,
    initialValue,
}) => {
    const { register, setValue } = useFormContext();
    const dispatch = useDispatch();

    useEffect(() => {
        register(name);
    }, [name, register]);

    const innerMethods = useForm<Exclude<SectionContent['image'], undefined>>({
        mode: 'onChange',
        defaultValues: initialValue,
    });

    const onChangeInner = () => {
        const innerValue = innerMethods.getValues();
        setValue(name, innerValue, {
            shouldValidate: true,
            shouldDirty: true,
        });

        if (onChange) {
            onChange();
        }
    };

    const altValue = innerMethods.watch('alt');
    const innerSetValue = innerMethods.setValue;

    useEffect(() => {
        if (!altValue) {
            innerSetValue('alt', undefined);
        }
    }, [altValue, innerSetValue]);

    const deleteImage = useCallback(() => {
        dispatch(sectionImageDeleteAsync.request({ id: sectionId }));
    }, [dispatch, sectionId]);

    return (
        <FormGroup>
            <div className={`fake-form-control ${className}`}>
                <div className="d-flex align-items-center">
                    <div>
                        <label className="mr-2 mb-0 d-block line-height-input">File</label>
                        <label className="mr-2 mb-0 d-block line-height-input">Alt</label>
                    </div>
                    <div className="w-100">
                        <div className="input-group mb-1">
                            <input
                                name="url"
                                readOnly={true}
                                className="form-control"
                                value={initialValue?.fileName}
                            />
                            <div className="input-group-append">
                                <Button variant="outline-input" onClick={deleteImage}>
                                    <Icon className="text-danger" icon={faTimes} />
                                </Button>
                            </div>
                        </div>
                        <FormProvider {...innerMethods}>
                            <input type="hidden" name="url" ref={innerMethods.register} />
                            <input type="hidden" name="contentType" ref={innerMethods.register} />
                            <input type="hidden" name="fileName" ref={innerMethods.register} />
                            <input
                                name="alt"
                                className="form-control"
                                ref={innerMethods.register}
                                onChange={onChangeInner}
                            />
                        </FormProvider>
                    </div>
                </div>
            </div>
        </FormGroup>
    );
};
