import { SiteContext } from '@careerhub/content-shared';
import { Site } from '@careerhub/content-shared';
import { createAsyncAction } from 'typesafe-actions';
import { ApiResponse } from '../../../models/api-response.models';
import { ErrorNormalized } from '../../../models/error.models';
import { IdModel } from '../../../models/id.models';

export const siteContextAsync = createAsyncAction(
    'SITE_CONTEXT:REQUEST',
    'SITE_CONTEXT:SUCCESS',
    'SITE_CONTEXT:FAILURE',
    'SITE_CONTEXT:CANCEL'
)<undefined, ApiResponse<SiteContext>, ErrorNormalized, undefined>();

export const sitePublishAsync = createAsyncAction(
    'SITE_PUBLISH:REQUEST',
    'SITE_PUBLISH:SUCCESS',
    'SITE_PUBLISH:FAILURE'
)<undefined, ApiResponse<SiteContext>, ErrorNormalized>();

export const siteUpdateAsync = createAsyncAction(
    'SITE_UPDATE:REQUEST',
    'SITE_UPDATE:SUCCESS',
    'SITE_UPDATE:FAILURE'
)<Site, ApiResponse<Site>, [ErrorNormalized, IdModel]>();
