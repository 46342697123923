import omit from 'lodash/omit';
import { Observable, of, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, switchMap } from 'rxjs/operators';
import { UnwrappedJsonResponse } from '../../models/api-response.models';
import { ErrorNormalized } from '../../models/error.models';
import { storeRegistry } from '../store.registry';

function isResponse<T = any>(
    wrappedResponse: any
): wrappedResponse is UnwrappedJsonResponse<T> {
    return (
        !!wrappedResponse &&
        !!wrappedResponse.response &&
        typeof wrappedResponse.response.ok === 'boolean'
    );
}

const HEADERS: Record<string, string> = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

// this doesn't get used in this site at the moment
// but it's likely to be necessary in the future, so i'm keeping it here
export interface FetchHelperOptions {
    aPossibleOption?: boolean;
}

// should include authentication token eventually
const getHeaders: (
    options?: FetchHelperOptions
) => Record<string, string> = () => {
    const store = storeRegistry.get();
    const token = store.getState().auth.user?.access_token;
    const authHeader: Record<string, string> = token
        ? { Authorization: `Bearer ${token}` }
        : {};
    return { ...HEADERS, ...authHeader };
};

const get: <T>(
    url: string,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, options) =>
    fetchRequest(url, 'GET', undefined, options);

const post: <T>(
    url: string,
    body: any,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, body, options) =>
    fetchRequest(url, 'POST', body, options);

const put: <T>(
    url: string,
    body: any,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, body, options) =>
    fetchRequest(url, 'PUT', body, options);

const del: <T>(
    url: string,
    options?: FetchHelperOptions
) => Observable<UnwrappedJsonResponse<T>> = (url, options) =>
    fetchRequest(url, 'DELETE', undefined, options);

const fetchRequest = <T>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    body?: any,
    options?: FetchHelperOptions
) => {
    // bleh, this could be better, it's only ever manipulated by the attachment control
    const isFormDataRequest = body instanceof FormData;
    const headers = isFormDataRequest
        ? omit(getHeaders(options), 'Content-Type')
        : getHeaders(options);

    // replaces undefined values to null
    // since undefined doesn't exist in JSON
    const bodyToSend = body
        ? isFormDataRequest
            ? body
            : JSON.stringify(body, (_, value) =>
                  typeof value === 'undefined' ? null : value
              )
        : undefined;

    return fromFetch(url, {
        method: method,
        mode: 'cors',

        body: bodyToSend,
        credentials: 'include',
        headers: headers,
    }).pipe(
        switchMap(async response => {
            const contentType = response.headers.get('Content-Type');

            let json: any = undefined;
            let text: string | undefined = undefined;

            if (contentType && contentType.indexOf('application/json') !== -1) {
                json = await response.json();
            } else {
                text = await response.text();
            }

            const toReturn: UnwrappedJsonResponse<T> = {
                response,
                json,
                text,
            };

            return toReturn;
        }),
        switchMap(awaited => {
            if (awaited.response.ok) {
                // OK return data
                return of(awaited);
            } else {
                // this handles all responses that return a non 200-299
                // this will be normalised in the below catch error
                return throwError(awaited);
            }
        }),
        catchError((err: UnwrappedJsonResponse<any> | unknown) => {
            console.error('catching an error here', err);

            // Network or other error, handle appropriately
            if (!isResponse(err)) {
                const nonResponseError: ErrorNormalized = {
                    status: 0,
                    statusText: 'Unknown',
                    message: (err as Error)?.message || 'Unexpected Error',
                };
                return throwError(nonResponseError);
            }

            // there are two main alt cases
            // 400 bad requests which are usually form validation errors
            // authentication responses (401 - unauthenticated)
            const responseError: ErrorNormalized = {
                status: err.response.status,
                statusText: err.response.statusText || 'Unknown',
                message:
                    err.json?.message ||
                    err.text ||
                    err.response.statusText ||
                    'Unexpected Error',
            };

            if (err.response.status === 400 && err.json) {
                Object.keys(err.json).forEach(key => {
                    // const propertyName = key.substring(
                    //     key.lastIndexOf('.') + 1
                    // );
                    responseError.message += '\n';
                    const property = err.json[key];
                    if (Array.isArray(property)) {
                        responseError.message += property.join(', ');
                    } else {
                        responseError.message += `${property}`;
                    }
                });
            }

            return throwError(responseError);
        })
    );
};

export const fetchHelper = {
    post,
    put,
    del,
    get,
    fetch: fetchRequest,
};
