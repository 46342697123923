import { Site } from '@careerhub/content-shared';
import keyBy from 'lodash/keyBy';
import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/error.models';
import { ItemState } from '../../../models/state.models';
import { initialStateHelper } from '../../helpers/initial-state.helper';
import { updateItemHelper } from '../../reducer-handlers/update-item.helper';
import { siteContextAsync, sitePublishAsync, siteUpdateAsync } from './site.actions';

export type SiteState = ItemState<Site> & {
    publishState: LoaderState;
};

const initialState: SiteState = {
    ...initialStateHelper.itemState(),
    publishState: {
        loading: false,
    },
};

const siteReducer = createReducer(initialState)
    .handleAction(siteContextAsync.success, (state, action) => ({
        ...state,
        items: {
            ...state.items,
            ...keyBy(action.payload.data.site ? [action.payload.data.site] : [], c => c.id),
        },
    }))

    .handleAction(sitePublishAsync.request, state => ({
        ...state,
        publishState: {
            loading: true,
        },
    }))
    .handleAction(sitePublishAsync.success, state => ({
        ...state,
        publishState: {
            loading: false,
        },
    }))
    .handleAction(sitePublishAsync.failure, (state, action) => ({
        ...state,
        publishState: {
            loading: false,
            error: action.payload,
        },
    }))

    .handleAction(siteUpdateAsync.request, updateItemHelper.request)
    .handleAction(siteUpdateAsync.success, updateItemHelper.success)
    .handleAction(siteUpdateAsync.failure, updateItemHelper.failure);

export default siteReducer;
