import { faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { componentCreateAsync } from '../../../logic/features/components/component.actions';
import { Icon } from '../shared/Icon';
import { LoaderSpinner } from '../shared/LoaderSpinner';

export const ComponentListAdd = () => {
    const dispatch = useDispatch();
    const activeCollectionId = useRootSelector(state => state.collections.activeId);
    const createState = useRootSelector(state => state.components.createState);
    const addComponent = () => {
        dispatch(
            componentCreateAsync.request({
                collectionId: activeCollectionId!,
                variant: 'alternate',
            })
        );
    };

    return (
        <>
            <Button
                variant="link"
                className="pl-3"
                onClick={() => addComponent()}
                disabled={createState.loading}
            >
                <LoaderSpinner size="sm" state={createState}>
                    <Icon icon={faPlus} />
                </LoaderSpinner>
                <span className="ml-2">Add component</span>
            </Button>
            {createState.error && (
                <span className="text-danger pr-2">
                    <Icon icon={faExclamationTriangle} size="lg">
                        {createState.error.message}
                    </Icon>
                </span>
            )}
        </>
    );
};
