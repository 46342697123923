import { SiteContext } from '@careerhub/content-shared';
import { Site } from '@careerhub/content-shared';
import { ApiResponse, ObsApiExpected } from '../../../models/api-response.models';
import { fetchHelper } from '../../helpers/fetch.helper';
import { generateUrl } from '../../helpers/url.helper';

export const getContext = (): ObsApiExpected<ApiResponse<SiteContext>> =>
    fetchHelper.get(generateUrl(['draft']));

export const publish = (): ObsApiExpected<ApiResponse<SiteContext>> =>
    fetchHelper.post(generateUrl(['publish']), undefined);

export const update = (site: Site): ObsApiExpected<ApiResponse<Site>> => {
    return fetchHelper.put(generateUrl([]), site);
};
