import { useDispatch } from 'react-redux';
import { useCollection } from '../../hooks/content.hook';
import {
    collectionModeSetActive,
    collectionUpdateAsync,
} from '../../../logic/features/collections/collection.actions';
import { LoaderPlaceholder } from '../shared/LoaderPlaceholder';
import { CollectionDropdown } from './CollectionDropdown';
import { CollectionForm, CollectionFormData } from './CollectionForm';

export const CollectionPageTitle = () => {
    const { active, state, activeMode } = useCollection();

    const dispatch = useDispatch();

    const onBlur = () => {
        dispatch(collectionModeSetActive({ mode: 'view' }));
    };
    const onSubmit = (data: CollectionFormData) => {
        if (!active) {
            return;
        }

        dispatch(collectionUpdateAsync.request({ ...data, id: active.id }));
    };

    if (!active) {
        return null;
    }

    return (
        <LoaderPlaceholder state={state?.deleteState}>
            <LoaderPlaceholder state={state?.updateState} onProperties={['title']}>
                {activeMode === 'view' ? (
                    <div className="d-flex align-items-center">
                        <h1 className="mb-0 d-inline text-dark text-long-title">{active.title}</h1>
                        <CollectionDropdown />
                    </div>
                ) : (
                    <div className="mr-3">
                        <CollectionForm onInputBlur={onBlur} onSubmit={onSubmit} />
                    </div>
                )}
            </LoaderPlaceholder>
        </LoaderPlaceholder>
    );
};
