import { Button, Modal } from 'react-bootstrap';

interface Props {
    onConfirm: () => any;
    onCancel: () => any;
}

export const DeleteModal: React.FC<Props> = ({ onCancel, onConfirm, children }) => {
    return (
        <Modal size="sm" show={true} animation={false}>
            <Modal.Header>
                <Modal.Title>Confirm</Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onCancel}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>{children ? children : 'Are you sure?'}</Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="danger" className="ml-0" onClick={onConfirm}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
