import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { orderCollections } from '../../../ui/hooks/content.hook';
import { collectionSetActive } from '../collections/collection.actions';
import { siteContextAsync, sitePublishAsync, siteUpdateAsync } from './site.actions';
import { asyncEpicStandard, asyncEpicBase } from '../../helpers/epics/async-epic.helper';
import { RootEpic } from '../epic.root-index';

export const siteContextAsyncEpic = asyncEpicStandard(siteContextAsync, ({ api }) =>
    api.site.getContext()
);

export const sitePublishAsyncEpic = asyncEpicStandard(sitePublishAsync, ({ api }) =>
    api.site.publish()
);

export const siteUpdateAsyncEpic = asyncEpicBase(
    siteUpdateAsync,
    ({ api }, action) => api.site.update(action.payload),
    {
        success: result => siteUpdateAsync.success(result.json),
        failure: (error, requestAction) => siteUpdateAsync.failure(error, requestAction.payload),
    }
);

export const siteContextSuccessSetActiveCollection: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(siteContextAsync.success)),
        filter(() => !state$.value.collections.activeId),
        mergeMap(action => {
            const activeKey = state$.value.content.activeKey;

            const ordered = orderCollections(
                state$.value.collections.items,
                activeKey,
                action.payload.data.site?.collectionPriority || []
            )[0];

            return ordered ? of(collectionSetActive({ id: ordered.id })) : EMPTY;
        })
    );
};
