import { ComponentContent, ComponentContentCreate } from '@careerhub/content-shared';
import { createAsyncAction } from 'typesafe-actions';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse } from '../../../models/api-response.models';
import { ErrorNormalized } from '../../../models/error.models';
import { IdModel } from '../../../models/id.models';

export const componentCreateAsync = createAsyncAction(
    'COMPONENT_CREATE:REQUEST',
    'COMPONENT_CREATE:SUCCESS',
    'COMPONENT_CREATE:FAILURE'
)<ComponentContentCreate, ApiResponse<ComponentContent>, ErrorNormalized>();

export const componentUpdateAsync = createAsyncAction(
    'COMPONENT_UPDATE:REQUEST',
    'COMPONENT_UPDATE:SUCCESS',
    'COMPONENT_UPDATE:FAILURE'
)<
    IdRequestPartialModel<ComponentContent>,
    ApiResponse<ComponentContent>,
    [ErrorNormalized, IdModel]
>();

export const componentDeleteAsync = createAsyncAction(
    'COMPONENT_DELETE:REQUEST',
    'COMPONENT_DELETE:SUCCESS',
    'COMPONENT_DELETE:FAILURE'
)<IdModel, ApiResponse<ComponentContent>, [ErrorNormalized, IdModel]>();
