import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../../hooks/root-selector.hook';
import { authenticationLogout } from '../../../../logic/features/authentication/authentication.actions';
import { Icon } from '../../shared/Icon';

export const LogoutRibbonItem = () => {
    const dispatch = useDispatch();
    const userName = useRootSelector(state => state.auth.user?.profile.name);

    const tempLogout = () => {
        dispatch(authenticationLogout());
    };

    return (
        <OverlayTrigger
            placement="right"
            overlay={props => (
                <Tooltip {...props} id="tooltip-logout">
                    {userName}
                    <br />
                    Click to logout.
                </Tooltip>
            )}
        >
            <Button variant="link-light" className="py-2 nav-icon-button" onClick={tempLogout}>
                <Icon icon={faSignOutAlt} size="lg" />
            </Button>
        </OverlayTrigger>
    );
};
