import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { ComponentContent } from '@careerhub/content-shared';
import { useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Nav, Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import {
    componentDeleteAsync,
    componentUpdateAsync,
} from '../../../logic/features/components/component.actions';
import { SectionList } from '../sections/SectionList';
import { DeletePopover } from '../shared/DeletePopover';
import { Icon } from '../shared/Icon';
import { ComponentForm } from './ComponentForm';

interface Props {
    componentId: string;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ComponentTabs: React.FC<Props> = ({ componentId, dragHandleProps }) => {
    const [activeKey, setActiveKey] = useState('sections');

    const component = useRootSelector(state => state.components.items[componentId]);

    const dispatch = useDispatch();
    const onSelect = (key: string | null) => {
        if (!key) {
            return;
        }
        setActiveKey(key);
    };

    const onDelete = () => {
        dispatch(
            componentDeleteAsync.request({
                id: component!.id,
            })
        );
    };

    const onVariantSubmit = (data: Partial<ComponentContent>) => {
        dispatch(componentUpdateAsync.request({ ...data, id: componentId }));
    };

    if (!component) {
        return null;
    }

    return (
        <Tab.Container activeKey={activeKey} onSelect={key => onSelect(key)} transition={false}>
            <Nav variant="tabs" className="variant-tab-container">
                <Nav.Item>
                    <div
                        className={`d-flex nav-link p-0 ${
                            activeKey === 'sections' ? 'active' : ''
                        }`}
                    >
                        <div className="pl-3 pr-2 align-self-center" {...dragHandleProps}>
                            <Icon className="fa-w-14" icon={faGripVertical} />
                        </div>
                        <a
                            href="#"
                            className={`d-block text-decoration-none py-2 pr-3 ${
                                activeKey === 'sections' ? 'text-dark' : ''
                            }`}
                            onClick={() => onSelect('sections')}
                        >
                            Sections <i className="small">{component.variant}</i>
                        </a>
                    </div>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="options">Options</Nav.Link>
                </Nav.Item>

                <div className="ml-auto">
                    <DeletePopover
                        overlayKey={`delete-component-${componentId}`}
                        onDelete={onDelete}
                        linkClassName="d-block pr-3"
                    >
                        <p>
                            This action will delete <strong>all sections</strong> in this variant.
                        </p>
                    </DeletePopover>
                </div>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="sections">
                    <SectionList component={component} />
                </Tab.Pane>
                <Tab.Pane eventKey="options">
                    <div className="p-3 bg-white border-left border-right border-bottom">
                        <ComponentForm component={component} onSubmit={onVariantSubmit} />
                    </div>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
};
