import { CollectionContentCreate, ContentKey } from '@careerhub/content-shared';
import values from 'lodash/values';
import { RootState } from '../reducer.root-index';

const newCollectionName = 'New Collection';

export function generateTitle(siteId: ContentKey, state: RootState): string {
    const existingTitles = values(state.collections.items)
        .filter(c => !!c && c.siteId === siteId)
        .map(c => c!.title);

    let title: string;
    if (!existingTitles.includes(newCollectionName)) {
        title = newCollectionName;
    } else {
        let count = 0;
        title = newCollectionName;
        do {
            title = `${newCollectionName} ${++count}`;
        } while (existingTitles.includes(title));
    }

    return title;
}

export function generate(siteId: ContentKey, state: RootState): CollectionContentCreate {
    const title = generateTitle(siteId, state);

    const toReturn: CollectionContentCreate = {
        siteId,
        title,
    };

    return toReturn;
}
