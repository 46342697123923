import * as actions from './component.actions';
import { asyncEpicStandard, asyncEpicBase } from '../../helpers/epics/async-epic.helper';

export const componentCreateAsyncEpic = asyncEpicStandard(
    actions.componentCreateAsync,
    ({ api }, { payload }) => api.component.create(payload)
);

export const componentDeleteAsyncEpic = asyncEpicBase(
    actions.componentDeleteAsync,
    ({ api }, { payload }) => api.component.del(payload),
    {
        success: result => actions.componentDeleteAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.componentDeleteAsync.failure(error, requestAction.payload),
    }
);

export const componentUpdateAsyncEpic = asyncEpicBase(
    actions.componentUpdateAsync,
    ({ api }, { payload }) => api.component.update(payload),
    {
        success: result => actions.componentUpdateAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.componentUpdateAsync.failure(error, requestAction.payload),
    }
);
