import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { concat, EMPTY, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';
import {
    ContentPageParams,
    routePathCreator,
    routePaths,
} from '../../../ui/components/routes/RouteList';
import { orderCollections } from '../../../ui/hooks/content.hook';
import { collectionSetActive } from '../collections/collection.actions';
import * as actions from './content.actions';
import { sectionSetActive } from '../sections/section.actions';
import { siteContextAsync } from '../sites/site.actions';
import { skipUntilAppInitComplete } from '../../helpers/epics/app-init.helper';
import { getRouteMatch } from '../../helpers/epics/location-epic.helper';
import { RootEpic } from '../epic.root-index';

export const contentSetActiveEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        skipUntilAppInitComplete,
        filter(isOfType(LOCATION_CHANGE)),
        mergeMap(action => {
            const params = getRouteMatch<ContentPageParams>(action.payload.location, [
                // order matters
                routePaths.contentCollectionSection,
                routePaths.contentCollection,
                routePaths.siteContent,
            ])?.params;

            if (!params) {
                return EMPTY;
            }

            const activeKey = state$.value.content.activeKey;
            const activeCollectionId = state$.value.collections.activeId;
            const activeSectionId = state$.value.sections.activeSectionId;

            const actionsToReturn = [
                activeKey === params.contentKey
                    ? undefined
                    : actions.contentSetActive({ key: params.contentKey }),
                activeCollectionId === params.collectionId
                    ? undefined
                    : collectionSetActive({ id: params.collectionId }),
                activeSectionId === params.sectionId
                    ? undefined
                    : sectionSetActive({ id: params.sectionId }),
            ]
                .filter(i => i !== undefined)
                .map(i => i!);

            return concat(actionsToReturn);
        })
    );
};

export const contentSetCollectionRedirectEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        skipUntilAppInitComplete,
        filter(isActionOf(collectionSetActive)),
        filter(action => !action.payload.id),
        mergeMap(() => {
            const activeKey = state$.value.content.activeKey;
            // lol
            const collectionId = orderCollections(
                state$.value.collections.items,
                activeKey,
                state$.value.sites.items[activeKey]?.collectionPriority
            )[0]?.id;

            if (!collectionId) {
                return EMPTY;
            }

            const path = routePathCreator.content({
                contentKey: activeKey,
                tenant: state$.value.content.tenant,
                collectionId,
            });

            return of(replace(path));
        })
    );
};

export const contentSetActiveRequestSite: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.contentSetActive)),
        mergeMap(() => of(siteContextAsync.request()))
    );
};

export const contentSetActivePublishPathEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        skipUntilAppInitComplete,
        filter(isOfType(LOCATION_CHANGE)),
        map(action =>
            getRouteMatch<ContentPageParams>(action.payload.location, routePaths.sitePublish)
        ),
        filter(match => !!match && match.params.contentKey !== state$.value.content.activeKey),
        mergeMap(match => of(actions.contentSetActive({ key: match!.params.contentKey })))
    );
};
