import { Profile, User } from 'oidc-client';
import { createReducer } from 'typesafe-actions';
import { ErrorNormalized } from '../../../models/error.models';
import * as actions from './authentication.actions';

export type ContentUser = User & {
    profile: Profile & {
        ch_user_id?: number;
        ch_administrator_id?: number;
        ch_app_url?: string;
        ch_app_name?: string;
    };
};

export type AuthenticationState = Readonly<{
    started: boolean;
    complete: boolean;
    error?: ErrorNormalized;
    user: ContentUser | null;
}>;

const initialState: AuthenticationState = {
    started: false,
    complete: false,
    user: null,
};

const authenticationReducer = createReducer(initialState)
    .handleAction(actions.authenticationInit, state => ({
        ...state,
        started: true,
    }))
    .handleAction(actions.authenticationComplete, (state, action) => ({
        ...state,
        complete: true,
        user: action.payload.user,
    }))
    .handleAction(actions.authenticationSetUser, (state, action) => ({
        ...state,
        user: action.payload.user,
    }))
    .handleAction(actions.authenticationFailure, (state, action) => ({
        ...state,
        complete: true,
        error: action.payload.error,
    }));

export default authenticationReducer;
