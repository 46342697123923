import { CareerHubRibbonItem } from './CareerHubRibbonItem';
import { CollapseRibbonItem } from './CollapseRibbonItem';
import { ContentRibbonItem } from './ContentRibbonItem';
import { LogoutRibbonItem } from './LogoutRibbonItem';
import { PublishRibbonItem } from './PublishRibbonItem';

export const SideNavRibbon = () => {
    return (
        <div className="bg-primary-dark" style={{ padding: '1rem .75rem' }}>
            <div className="d-flex flex-column h-100">
                <div className="mb-2">
                    <CareerHubRibbonItem />
                </div>
                <div className="mb-2 mt-1">
                    <ContentRibbonItem />
                </div>
                <div className="mb-2">
                    <PublishRibbonItem />
                </div>
                <div className="mt-auto mb-2">
                    <CollapseRibbonItem />
                </div>
                <LogoutRibbonItem />
            </div>
        </div>
    );
};
