import { ComponentContent } from '@careerhub/content-shared';
import { useCallback, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { reorder } from '../../../logic/helpers/array.helper';
import { useCloneList } from '../../hooks/clone-list.hook';
import { useSections } from '../../hooks/content.hook';
import { componentUpdateAsync } from '../../../logic/features/components/component.actions';
import { DragWrapper, DragWrapperDropResult } from '../shared/DragWrapper';
import { SectionListAdd } from './SectionListAdd';
import { SectionListItem } from './SectionListItem';

interface Props {
    component: ComponentContent;
}

export const SectionList: React.FC<Props> = ({ component }) => {
    const dispatch = useDispatch();

    const sections = useSections(component.id);
    const sectionIds = useMemo(() => sections.map(s => s.id), [sections]);

    const [cloneList, setCloneList] = useCloneList(sections);

    const onDragEnd = (result: DragWrapperDropResult) => {
        if (!result.destination) {
            return;
        }

        const toSave = reorder(
            sectionIds,
            result.source.index,
            result.destination.index
        );
        dispatch(
            componentUpdateAsync.request({
                id: component.id,
                sectionPriority: toSave,
            })
        );
        setCloneList(cloneList =>
            toSave.map(id => cloneList.find(c => c.id === id)!)
        );
    };

    const getItemClassName = useCallback(
        (index: number) =>
            `list-group-item-action-fake ${index === 0 ? 'border-top-0' : ''}`,
        []
    );

    return (
        <ListGroup
            variant="flush"
            className="bg-white border-left border-right border-bottom"
        >
            <DragWrapper onDragEnd={onDragEnd} droppableId={component.id}>
                {cloneList.map((section, sectionIndex) => {
                    const uniqueKey = `section-${section.id}`;
                    return (
                        <DragWrapper.Inner
                            draggableId={uniqueKey}
                            index={sectionIndex}
                            key={uniqueKey}
                            itemClassName={getItemClassName(sectionIndex)}
                        >
                            {provided => (
                                <SectionListItem
                                    section={section}
                                    dragHandleProps={
                                        provided.dragHandleProps || undefined
                                    }
                                />
                            )}
                        </DragWrapper.Inner>
                    );
                })}
            </DragWrapper>
            <SectionListAdd componentId={component.id} />
        </ListGroup>
    );
};
