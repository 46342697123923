import { ContentKey } from '@careerhub/content-shared';
import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/error.models';
import { authenticationComplete } from '../authentication/authentication.actions';
import * as actions from './content.actions';
import { siteContextAsync } from '../sites/site.actions';
import { decodeContentKeys } from '../../helpers/jwt.helper';

export const stateActiveKeyPlaceholder = '__';

export type ContentState = {
    activeKey: ContentKey;
    keys: ContentKey[];
    tenant: string;

    contextState: LoaderState;
};

const initialState: ContentState = {
    keys: [],
    activeKey: stateActiveKeyPlaceholder as any,
    tenant: '__',

    contextState: {
        loading: false,
    },
};

const contentReducer = createReducer(initialState)
    .handleAction(authenticationComplete, (state, action) => {
        const decoded = action.payload.user
            ? decodeContentKeys(action.payload.user.access_token)
            : undefined;

        return {
            ...state,
            keys: decoded?.siteAccess || [],
            tenant: decoded?.ch_key || initialState.tenant,
        };
    })
    .handleAction(siteContextAsync.request, state => ({
        ...state,
        contextState: {
            loading: true,
        },
    }))
    .handleAction(siteContextAsync.failure, (state, action) => ({
        ...state,
        contextState: {
            loading: false,
            error: action.payload,
        },
    }))
    .handleAction(siteContextAsync.cancel, state => ({
        ...state,
        contextState: {
            loading: false,
        },
    }))
    .handleAction(siteContextAsync.success, state => {
        return {
            ...state,
            contextState: {
                loading: false,
            },
        };
    })
    .handleAction(actions.contentSetActive, (state, action) => ({
        ...state,
        activeKey: action.payload.key,
    }));

export default contentReducer;
