import { createElementId } from '@careerhub/content-shared';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRootSelector } from './root-selector.hook';

// pretty sure this is overkill, can be simplier
const isElementXPercentInViewport = function (el: HTMLElement, percentVisible = 100) {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return !(
        Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100) <
            percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    );
};

export const useContentLayout = () => {
    const { sideNavCollapsed, viewMode } = useRootSelector(state => state.layout);
    const { hoverSectionId, activeSectionId } = useRootSelector(state => state.sections);

    const heightRef = useRef<HTMLDivElement | null>(null);
    const widthRef = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const previousHoverRef = useRef<HTMLElement | null>(null);
    const previousActiveRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        setHeight(heightRef.current?.clientHeight || 0);
    }, []);

    useEffect(() => {
        setWidth(widthRef.current?.clientWidth || 0);
    }, [widthRef.current?.clientWidth, sideNavCollapsed]);

    useEffect(() => {
        if (previousActiveRef.current || viewMode === 'preview') {
            previousActiveRef.current?.classList.remove('active');
        }

        if (!activeSectionId) {
            return;
        }

        const elementId = createElementId(activeSectionId);
        const element = document.getElementById(elementId);

        if (!element) {
            return;
        }

        element.classList.add('active');
        previousActiveRef.current = element;
    }, [activeSectionId, viewMode]);

    useEffect(() => {
        if (previousHoverRef.current) {
            previousHoverRef.current.classList.remove('hover');
        }

        if (!hoverSectionId) {
            return;
        }

        const elementId = createElementId(hoverSectionId);
        const element = document.getElementById(elementId);
        if (!element) {
            return;
        }

        element.classList.add('hover');
        if (!isElementXPercentInViewport(element)) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        previousHoverRef.current = element;
    }, [hoverSectionId]);

    const heightStyle = useMemo(() => {
        return `calc(100vh - ${height}px)`;
    }, [height]);

    const widthStyle = useMemo(() => {
        return `calc(100% - ${width}px)`;
    }, [width]);

    return {
        heightRef,
        widthRef,
        heightStyle,
        widthStyle,
    };
};
