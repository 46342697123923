import { SectionContent } from '@careerhub/content-shared';
import { useMemo } from 'react';
import { FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTimer } from '../../hooks/timer.hook';
import { LoaderState } from '../../../models/error.models';
import { sectionFormDelay } from './SectionFormContainer';

type SectionHeader = Pick<SectionContent, 'header'>;
const PROP_NAME: keyof SectionHeader = 'header';

interface Props {
    defaultValues?: SectionHeader;
    onSubmit: (data: SectionHeader) => void;
    state: LoaderState | undefined;
}

export const SectionHeaderForm: React.FC<Props> = ({ defaultValues, onSubmit, state }) => {
    const { triggerDelay, isDelaying } = useTimer(sectionFormDelay);

    const { handleSubmit, formState, register } = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const onChange = () => {
        triggerDelay(() => {
            handleSubmit(onSubmit)();
        });
    };

    const formClassName = useMemo(() => {
        if (isDelaying || state?.loading || state?.error) {
            return 'form-control';
        }

        return `form-control ${formState.isDirty && formState.submitCount > 0 ? 'is-valid' : ''}`;
    }, [formState.isDirty, formState.submitCount, isDelaying, state]);

    const spinnerClassName = useMemo(() => {
        return `input-container-spinner ${
            state?.loading && state?.properties?.includes(PROP_NAME) ? 'is-waiting' : ''
        }`;
    }, [state]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <label>
                    <span className="mr-2">Header</span>
                </label>
                <div className={spinnerClassName}>
                    <input
                        name={PROP_NAME}
                        className={formClassName}
                        ref={register}
                        onChange={onChange}
                    />
                </div>
            </FormGroup>
        </form>
    );
};
