import { asyncEpicBase } from '../../helpers/epics/async-epic.helper';
import * as actions from './section.actions';

export const sectionCreateAsyncEpic = asyncEpicBase(
    actions.sectionCreateAsync,
    ({ api }, { payload }) => api.section.create(payload),
    {
        success: result => actions.sectionCreateAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.sectionCreateAsync.failure(error, requestAction.payload),
    }
);

export const sectionDeleteAsyncEpic = asyncEpicBase(
    actions.sectionDeleteAsync,
    ({ api }, { payload }) => api.section.del(payload),
    {
        success: result => actions.sectionDeleteAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.sectionDeleteAsync.failure(error, requestAction.payload),
    }
);

export const sectionUpdateAsyncEpic = asyncEpicBase(
    actions.sectionUpdateAsync,
    ({ api }, { payload }) => api.section.update(payload),
    {
        success: result => actions.sectionUpdateAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.sectionUpdateAsync.failure(error, requestAction.payload),
    }
);

export const sectionImageUploadAsyncEpic = asyncEpicBase(
    actions.sectionImageUploadAsync,
    ({ api }, { payload }) => api.section.uploadImage(payload),
    {
        success: result => actions.sectionImageUploadAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.sectionImageUploadAsync.failure(error, requestAction.payload),
    }
);

export const sectionImageDeleteAsyncEpic = asyncEpicBase(
    actions.sectionImageDeleteAsync,
    ({ api }, { payload }) => api.section.deleteImage(payload),
    {
        success: result => actions.sectionImageDeleteAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.sectionImageDeleteAsync.failure(error, requestAction.payload),
    }
);
