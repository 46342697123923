import { getConfig } from '../../config/configuration';
import { storeRegistry } from '../store.registry';

export const generateUrl = (path: string[]): string => {
    const {
        content: { activeKey, tenant },
    } = storeRegistry.get().getState();

    return [
        getConfig().basePath, // base path
        tenant, // tenant key
        activeKey, // site key
        ...path,
    ].join('/');
};
