import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import services, { Services } from './features/service.root-index';
import rootEpic from './features/epic.root-index';
import rootReducer, { RootState, routerHistory } from './features/reducer.root-index';
import { sentryEnhancer } from './store-enhancers/sentry.enhancer';
import { RootAction } from './features/action.root-index';

const epicMiddleWare = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services,
});

const middlewares = [routerMiddleware(routerHistory), epicMiddleWare];

const enhancer = composeWithDevTools(applyMiddleware(...middlewares), sentryEnhancer);

const initialState = {};

const configureStore = () => {
    const store = createStore(rootReducer, initialState, enhancer);
    epicMiddleWare.run(rootEpic);
    return store;
};

export default configureStore;
