import { SectionContent } from '@careerhub/content-shared';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTimer } from '../../hooks/timer.hook';
import { LoaderState } from '../../../models/error.models';
import { ImageControl } from '../forms/ImageControl';
import { sectionFormDelay } from './SectionFormContainer';

type SectionImage = Pick<SectionContent, 'image'>;
const PROP_NAME: keyof SectionImage = 'image';

interface Props {
    section: SectionContent;
    onSubmit: (data: SectionImage) => void;
    state: LoaderState | undefined;
}

export const SectionImageForm: React.FC<Props> = ({ section, onSubmit, state }) => {
    const { isDelaying, triggerDelay } = useTimer(sectionFormDelay);

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: section.image,
    });

    const {
        handleSubmit,
        formState: { isDirty, submitCount, isValid },
    } = formMethods;

    const onChange = () => {
        triggerDelay(() => {
            handleSubmit(onSubmit)();
        });
    };

    const className = useMemo(() => {
        if (!isValid) {
            return 'is-invalid';
        }

        if (state?.loading && state?.properties?.includes(PROP_NAME)) {
            return 'input-container-spinner is-waiting';
        }

        if (isDelaying || state?.loading || state?.error) {
            return '';
        }

        return isDirty && submitCount > 0 ? 'is-valid' : '';
    }, [isValid, isDelaying, state, isDirty, submitCount]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ImageControl
                    sectionId={section.id}
                    name={PROP_NAME}
                    className={className}
                    onChange={onChange}
                    initialValue={section.image}
                />
            </form>
        </FormProvider>
    );
};
