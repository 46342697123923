import { Configuration } from '../models/config.models';
import { configurationInit } from '../logic/features/initialization/initialization.actions';
import { storeRegistry } from '../logic/store.registry';
import { logError } from '../logic/helpers/sentry.helper';

export const executeConfigurationInit = (env: string | undefined) => {
    const store = storeRegistry.get();

    import(`./configuration.${env || 'local'}`)
        .then((imported: { config: Configuration }) => {
            store.dispatch(configurationInit(imported.config));
        })
        .catch(e => {
            logError(e);
        });
};

// only use this if you can't get the config from state
export const getConfig = () => storeRegistry.get().getState().init.config;
