import { createReducer } from 'typesafe-actions';
import { layoutSideNavCollapse, layoutViewMode } from './layout.actions';

export type LayoutViewMode = 'edit' | 'preview' | 'all';
export type LayoutState = {
    sideNavCollapsed: boolean;
    viewMode: LayoutViewMode;
};

const initialState: LayoutState = {
    sideNavCollapsed: false,
    viewMode: 'all',
};

const layoutReducer = createReducer(initialState)
    .handleAction(layoutSideNavCollapse, (state, action) => ({
        ...state,
        sideNavCollapsed: action.payload.collapse,
    }))
    .handleAction(layoutViewMode, (state, action) => ({
        ...state,
        viewMode: action.payload.viewMode,
    }));

export default layoutReducer;
