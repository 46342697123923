import { createContext } from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableChildrenFn,
    DraggingStyle,
    Droppable,
    DropResult,
    NotDraggingStyle,
} from 'react-beautiful-dnd';

interface DragInnerProps {
    draggableId: string;
    index: number;
    children: DraggableChildrenFn;
    itemClassName?: string;
}

const DragInnerComponent: React.FC<DragInnerProps> = ({
    draggableId,
    index,
    children,
    itemClassName,
}) => {
    const getItemStyle = (
        isDragging: boolean,
        draggableStyle?: DraggingStyle | NotDraggingStyle
    ) => ({
        // change background colour if dragging
        backgroundColor: isDragging ? 'lightgreen' : undefined,

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    return (
        <Draggable draggableId={draggableId} index={index}>
            {(provided, snapshot, rubric) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        className={itemClassName}
                    >
                        {children(provided, snapshot, rubric)}
                    </div>
                );
            }}
        </Draggable>
    );
};

export type DragWrapperDropResult = DropResult &
    Required<Pick<DropResult, 'destination'>>;

interface DragWrapperProps {
    droppableId: string;
    onDragEnd: (result: DragWrapperDropResult) => void;
    children?: React.ReactNode;
}

export const CustomDragContext = createContext<{
    droppableIsDraggingOverWith: undefined | string;
}>({
    droppableIsDraggingOverWith: undefined,
});

const DragWrapperComponent: React.FC<DragWrapperProps> = ({
    droppableId,
    onDragEnd,
    children,
}) => {
    const onDragEndInner = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        onDragEnd({ ...result, destination: result.destination! });
    };

    return (
        <DragDropContext onDragEnd={onDragEndInner}>
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => {
                    return (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <CustomDragContext.Provider
                                value={{
                                    droppableIsDraggingOverWith:
                                        snapshot.draggingOverWith || undefined,
                                }}
                            >
                                {children}
                                {provided.placeholder}
                            </CustomDragContext.Provider>
                        </div>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};

export const DragWrapper = (props: DragWrapperProps) => {
    return <DragWrapperComponent {...props} />;
};
DragWrapper.Inner = DragInnerComponent;
