import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../../hooks/root-selector.hook';
import { layoutSideNavCollapse } from '../../../../logic/features/layout/layout.actions';
import { Icon } from '../../shared/Icon';

export const CollapseRibbonItem = () => {
    const { sideNavCollapsed } = useRootSelector(state => state.layout);
    const dispatch = useDispatch();

    const setCollapse = () => {
        dispatch(layoutSideNavCollapse({ collapse: !sideNavCollapsed }));
    };

    return (
        <OverlayTrigger
            placement="right"
            overlay={props => (
                <Tooltip {...props} id="tooltip-collapse">
                    {sideNavCollapsed ? 'Expand' : 'Collapse'}
                </Tooltip>
            )}
        >
            <Button
                variant="link-light"
                className="py-2 nav-icon-button"
                onClick={() => setCollapse()}
            >
                <Icon
                    icon={sideNavCollapsed ? faAngleDoubleRight : faAngleDoubleLeft}
                    size="lg"
                    className="fa-w-18"
                />
            </Button>
        </OverlayTrigger>
    );
};
