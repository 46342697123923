import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { CombinedState, combineReducers, Reducer } from 'redux';
import auth, { AuthenticationState } from './authentication/authentication.reducer';
import collections, { CollectionState } from './collections/collection.reducer';
import components, { ComponentState } from './components/component.reducer';
import content, { ContentState } from './content/content.reducer';
import init, { InitializationState } from './initialization/initialization.reducer';
import layout, { LayoutState } from './layout/layout.reducer';
import sections, { SectionState } from './sections/section.reducer';
import sites, { SiteState } from './sites/site.reducer';

export const routerHistory = createBrowserHistory();
const routerReducer = connectRouter(routerHistory);

export type RootState = {
    router: RouterState<unknown>;
    init: InitializationState;
    auth: AuthenticationState;
    content: ContentState;
    layout: LayoutState;
    collections: CollectionState;
    components: ComponentState;
    sections: SectionState;
    sites: SiteState;
};

type RootReducer = Reducer<CombinedState<RootState>>;

const rootReducer: RootReducer = combineReducers({
    router: routerReducer,
    init,
    auth,
    content,
    layout,
    collections,
    components,
    sections,
    sites,
});

export default rootReducer;
