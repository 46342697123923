import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SectionContent } from '@careerhub/content-shared';
import { useMemo } from 'react';
import { Alert, Col, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { sectionUpdateAsync } from '../../../logic/features/sections/section.actions';
import { Icon } from '../shared/Icon';
import { SectionBodyForm } from './SectionBodyForm';
import { SectionHeaderForm } from './SectionHeaderForm';
import { SectionImageDropzone } from './SectionImageDropzone';
import { SectionImageForm } from './SectionImageForm';
import { SectionLinkForm } from './SectionLinkForm';

interface Props {
    section: SectionContent;
}

export const sectionFormDelay = 1000;

export const SectionFormContainer: React.FC<Props> = ({ section }) => {
    const dispatch = useDispatch();
    const { viewMode } = useRootSelector(state => state.layout);
    const isAll = useMemo(() => viewMode === 'all', [viewMode]);

    const onSubmit = (data: Partial<SectionContent>) => {
        dispatch(sectionUpdateAsync.request({ ...data, id: section.id }));
    };

    const itemState = useRootSelector(state => state.sections.itemStates[section.id]);
    const imageState = useRootSelector(state => state.sections.imageStates[section.id]);

    return (
        <>
            {itemState?.updateState?.error && (
                <Alert variant="danger">
                    <Icon size="lg" icon={faExclamationTriangle}>
                        {itemState?.updateState?.error.message}
                    </Icon>
                </Alert>
            )}
            <SectionHeaderForm
                defaultValues={{ header: section.header }}
                state={itemState?.updateState}
                onSubmit={onSubmit}
            />
            <SectionBodyForm
                defaultValues={{ body: section.body }}
                onSubmit={onSubmit}
                state={itemState?.updateState}
            />
            <Form.Row>
                <Col sm={12} lg={isAll ? 12 : 6}>
                    <label>Image</label>
                    {section.image && (
                        <SectionImageForm
                            state={itemState?.updateState}
                            section={section}
                            onSubmit={onSubmit}
                        />
                    )}
                    {!section.image && (
                        <SectionImageDropzone state={imageState} sectionId={section.id} />
                    )}
                </Col>
                <Col sm={12} lg={isAll ? 12 : 6}>
                    <SectionLinkForm
                        state={itemState?.updateState}
                        defaultValues={{ link: section.link }}
                        onSubmit={onSubmit}
                    />
                </Col>
            </Form.Row>
        </>
    );
};
