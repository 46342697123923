import { replace } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { combineLatest, EMPTY, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { routePathCreator, routePaths } from '../../../ui/components/routes/RouteList';
import { authenticationComplete } from '../authentication/authentication.actions';
import { appInit, appInitComplete, configurationInit } from './initialization.actions';
import { stateActiveKeyPlaceholder } from '../content/content.reducer';
import { RootEpic } from '../epic.root-index';

export const appInitEpic: RootEpic = action$ => {
    return action$.pipe(
        take(1),
        mergeMap(() => of(appInit()))
    );
};

export const appInitCompleteEpic: RootEpic = action$ => {
    return combineLatest([
        action$.pipe(filter(isActionOf(configurationInit)), take(1)),
        action$.pipe(filter(isActionOf(authenticationComplete)), take(1)),
    ]).pipe(mergeMap(() => of(appInitComplete())));
};

export const appInitCompleteRedirectEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(appInitComplete)),
        mergeMap(() => {
            // this logic is filthy
            const redirectUrl =
                state$.value.auth.user?.state?.redirectUrl ||
                `${state$.value.router.location.pathname}${
                    (state$.value.router as any).search || ''
                }`;

            const match = matchPath(redirectUrl, [
                // order matters
                routePaths.site,
                routePaths.tenant,
                routePaths.home,
            ]);

            if (!match || !match.isExact) {
                return of(replace(redirectUrl));
            }

            const { activeKey, keys, tenant } = state$.value.content;

            const keyToSet =
                (activeKey as string) === stateActiveKeyPlaceholder ? keys[0] : activeKey;

            // pretty sure this should be impossible to hit if authentication complete is fired
            if (!keyToSet) {
                return EMPTY;
            }

            const path = routePathCreator.content({
                contentKey: keyToSet,
                tenant: tenant,
            });

            return of(replace(path));
        })
    );
};
