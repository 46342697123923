import { SectionContent, SectionContentCreate } from '@careerhub/content-shared';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse, ObsApiExpected } from '../../../models/api-response.models';
import { IdModel, SectionImageRequest } from '../../../models/id.models';
import { fetchHelper } from '../../helpers/fetch.helper';
import { generateUrl } from '../../helpers/url.helper';

export const create = (
    section: SectionContentCreate
): ObsApiExpected<ApiResponse<SectionContent>> =>
    fetchHelper.post(generateUrl(['sections']), section);

export const update = (
    section: IdRequestPartialModel<SectionContent>
): ObsApiExpected<ApiResponse<SectionContent>> =>
    fetchHelper.put(generateUrl(['sections', section.id]), section);

export const del = (request: IdModel): ObsApiExpected<ApiResponse<SectionContent>> =>
    fetchHelper.del(generateUrl(['sections', request.id]));

export const uploadImage = (
    request: SectionImageRequest
): ObsApiExpected<ApiResponse<SectionContent>> => {
    const formData = new FormData();
    formData.append('file', request.file);

    return fetchHelper.post(generateUrl(['sections', request.sectionId, 'image']), formData);
};

export const deleteImage = (request: IdModel): ObsApiExpected<ApiResponse<SectionContent>> =>
    fetchHelper.del(generateUrl(['sections', request.id, 'image']));
