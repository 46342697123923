import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Alert, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { sitePublishAsync } from '../../../logic/features/sites/site.actions';
import { LayoutContainer } from '../layout/LayoutContainer';
import { SideNav } from '../nav/SideNav';
import { TopNav } from '../nav/TopNav';
import { Icon } from '../shared/Icon';
import { LoaderSpinner } from '../shared/LoaderSpinner';

export const PublishPage = () => {
    const dispatch = useDispatch();
    // const site = useSite();
    // const published = useMemo(() => {
    //     if (!site || !site.lastPublished) {
    //         return undefined;
    //     }

    //     return format(parseISO(site.lastPublished), 'PPp');
    // }, [site]);

    const onClick = () => {
        dispatch(sitePublishAsync.request());
    };

    const publishState = useRootSelector(state => state.sites.publishState);

    return (
        <LayoutContainer
            sideNav={<SideNav />}
            topNav={
                <TopNav>
                    <h1 className="mb-0 d-inline text-dark text-long-title">Publish</h1>
                </TopNav>
            }
        >
            <div className="p-3">
                {publishState.error && (
                    <Alert variant="danger">
                        <Icon icon={faExclamationTriangle} size="lg">
                            {publishState.error.message}
                        </Icon>
                    </Alert>
                )}
                <p>
                    Publishing this site will create a snapshot of the content which is then
                    distributed via a CDN (Content Delivery Network).
                </p>

                <p>
                    CDN's allow for incredibly efficient and performant delivery, particularly
                    within the geographical region where the content is hosted.
                </p>
                <p>
                    However, it may take some time to propagate the changes you have made once you
                    publish the site. This is normally within 1 or 2 minutes, but in some rare cases
                    may take longer, to a maximum of 24 hours.
                </p>
                <Button disabled={publishState.loading} onClick={onClick}>
                    Publish
                </Button>
                <LoaderSpinner state={publishState} spinnerClassName="ml-2" size="sm" />
            </div>
        </LayoutContainer>
    );
};
