import { faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { sectionCreateAsync } from '../../../logic/features/sections/section.actions';
import { Icon } from '../shared/Icon';
import { LoaderSpinner } from '../shared/LoaderSpinner';

interface Props {
    componentId: string;
}

export const SectionListAdd: React.FC<Props> = ({ componentId }) => {
    const dispatch = useDispatch();
    const createState = useRootSelector(state => state.sections.createState);
    const createForComponentIds = useRootSelector(state => state.sections.creatingForComponentIds);

    const loading = useMemo(
        () => createForComponentIds.includes(componentId) && createState.loading,
        [createForComponentIds, createState.loading, componentId]
    );

    const addSection = () => {
        dispatch(sectionCreateAsync.request({ componentId }));
    };

    return (
        <ListGroup.Item>
            <Button
                variant="link"
                className="pl-0 py-0"
                onClick={() => addSection()}
                disabled={loading}
            >
                <LoaderSpinner size="sm" state={createState} overrideStateLoading={loading}>
                    <Icon icon={faPlus} />
                </LoaderSpinner>
                <span className="ml-2">Add section</span>
            </Button>
            {createState.error && (
                <span className="text-danger pr-2">
                    <Icon icon={faExclamationTriangle} size="lg">
                        {createState.error.message}
                    </Icon>
                </span>
            )}
        </ListGroup.Item>
    );
};
