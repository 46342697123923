import { ContentKey } from '@careerhub/content-shared';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { contentSetActive } from '../../../logic/features/content/content.actions';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { routePathCreator } from '../routes/RouteList';
import { Icon } from '../shared/Icon';

type TitleMeta = {
    [key in ContentKey]: string;
};

const titleMeta: TitleMeta = {
    employerProtected: 'Employer Protected',
    employerContent: 'Employer Content',
};

interface Props {
    variant?: string;
}

export const ContentKeyDropdown: React.FC<Props> = ({ variant }) => {
    const { tenant, activeKey } = useRootSelector(state => state.content);
    const keys = useRootSelector(state => state.content.keys);

    const dispatch = useDispatch();

    const onClick = (key: ContentKey) => {
        if (activeKey === key) {
            return;
        }
        dispatch(contentSetActive({ key }));
    };

    return (
        <Dropdown>
            <Dropdown.Toggle
                id="content-key"
                className="btn-block btn-no-focus px-2 border-left-0 border-right-0 border-top-0 rounded-0 text-left border-bottom-1 no-icon border-lightfade"
                variant={variant || 'outline-light'}
            >
                <h1 className="mb-0 h3">
                    <Icon icon={faCaretDown}>{titleMeta[activeKey]}</Icon>
                </h1>
            </Dropdown.Toggle>
            <Dropdown.Menu id="content-key" className="w-100">
                {keys.map(key => (
                    <Dropdown.Item
                        key={key}
                        onClick={() => onClick(key)}
                        as={Link}
                        to={routePathCreator.content({ tenant, contentKey: key })}
                    >
                        {titleMeta[key]}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
