import { ComponentContent } from '@careerhub/content-shared';
import keyBy from 'lodash/keyBy';
import { createReducer } from 'typesafe-actions';
import { ItemState } from '../../../models/state.models';
import { siteContextAsync } from '../sites/site.actions';
import * as actions from './component.actions';
import { createItemHelper } from '../../reducer-handlers/create-item.helper';
import { deleteItemHelper } from '../../reducer-handlers/delete-item.helper';
import { initialStateHelper } from '../../helpers/initial-state.helper';
import { updateItemHelper } from '../../reducer-handlers/update-item.helper';

export type ComponentState = ItemState<ComponentContent>;

const initialState: ComponentState = initialStateHelper.itemState();

const componentReducer = createReducer(initialState)
    .handleAction(siteContextAsync.success, (state, action) => ({
        ...state,
        items: {
            ...state.items,
            ...keyBy(action.payload.data.components, c => c.id),
        },
    }))
    .handleAction(actions.componentCreateAsync.request, createItemHelper.request)
    .handleAction(actions.componentCreateAsync.success, createItemHelper.success)
    .handleAction(actions.componentCreateAsync.failure, createItemHelper.failure)

    .handleAction(actions.componentDeleteAsync.request, deleteItemHelper.request)
    .handleAction(actions.componentDeleteAsync.success, deleteItemHelper.success)
    .handleAction(actions.componentDeleteAsync.failure, deleteItemHelper.failure)

    .handleAction(actions.componentUpdateAsync.request, updateItemHelper.request)
    .handleAction(actions.componentUpdateAsync.success, updateItemHelper.success)
    .handleAction(actions.componentUpdateAsync.failure, updateItemHelper.failure);

export default componentReducer;
