import { useRootSelector } from '../../hooks/root-selector.hook';
import { ContentKeyDropdown } from './ContentKeyDropdown';
import { SideNavRibbon } from './ribbon/SideNavRibbon';

export const SideNav: React.FC = ({ children }) => {
    const { sideNavCollapsed } = useRootSelector(state => state.layout);

    return (
        <div className="border-right bg w-100 overflow-y">
            <div className="main-nav d-flex min-vh-100">
                <SideNavRibbon />

                {!sideNavCollapsed && (
                    <div className="bg-primary flex-grow-1 p-3">
                        <div className="mb-3 d-flex">
                            <div className="w-100">
                                <ContentKeyDropdown />
                            </div>
                        </div>

                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};
