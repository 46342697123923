import { ItemState } from '../../models/state.models';

const itemState = <TEntity>(): ItemState<TEntity> => ({
    createState: { loading: false },
    itemStates: {},
    items: {},
});

export const initialStateHelper = {
    itemState,
};
