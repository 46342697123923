import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    icon: IconProp;
    size?: SizeProp;
    className?: string;
}

export const Icon: React.FC<Props> = ({ icon, size, className, children }) => {
    return (
        <>
            <FontAwesomeIcon icon={icon} size={size} className={className} />
            {children && <span className="ml-2">{children}</span>}
        </>
    );
};
