import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useForm } from 'react-hook-form';
import { useCollection } from '../../hooks/content.hook';
import { useTimer } from '../../hooks/timer.hook';
import { requiredRule } from '../../../logic/helpers/validation.helper';
import { Icon } from '../shared/Icon';

interface Props {
    onInputBlur: () => void;
    onSubmit: (data: CollectionFormData) => void;
}

export interface CollectionFormData {
    title: string;
}

export const CollectionForm: React.FC<Props> = ({ onInputBlur, onSubmit }) => {
    const { active } = useCollection();

    const { handleSubmit, register, errors } = useForm<CollectionFormData>({
        mode: 'onChange',
        defaultValues: { title: active?.title },
    });

    const { triggerDelay } = useTimer();

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        triggerDelay(() => e.target.select());
    };

    const onInputBlurInner = () => {
        setTimeout(() => onInputBlur(), 1);
    };

    return (
        <form className="form-inline w-100" onSubmit={handleSubmit(onSubmit)}>
            <input
                autoFocus
                name="title"
                className={`form-control form-control-sm flex-grow-1 ${
                    errors.title ? 'is-invalid' : ''
                }`}
                ref={register({
                    required: requiredRule,
                })}
                onFocus={onFocus}
                onBlur={onInputBlurInner}
            />
            {errors.title && (
                <span className="small text-danger ml-2">
                    <Icon icon={faExclamationTriangle}>{errors.title.message}</Icon>
                </span>
            )}
        </form>
    );
};
