import { faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { collectionCreateDefault } from '../../../logic/features/collections/collection.actions';
import { Icon } from '../shared/Icon';
import { LoaderSpinner } from '../shared/LoaderSpinner';

interface Props {
    variant?: string;
}

export const CollectionAdd: React.FC<Props> = ({ variant }) => {
    const dispatch = useDispatch();
    const createState = useRootSelector(state => state.collections.createState);

    const onAdd = () => {
        dispatch(collectionCreateDefault());
    };

    return (
        <>
            {createState.error && (
                <div className="text-danger px-2 pt-2">
                    <Icon icon={faExclamationTriangle} size="lg">
                        {createState.error.message}
                    </Icon>
                </div>
            )}
            <Button
                variant={variant || 'link-white'}
                className="p-2 pt-3"
                onClick={onAdd}
                disabled={createState.loading}
            >
                <LoaderSpinner state={createState} size="sm">
                    <Icon icon={faPlus} />
                </LoaderSpinner>
                <span className="ml-2">Add collection</span>
            </Button>
        </>
    );
};
