import { User } from 'oidc-client';
import { createAction } from 'typesafe-actions';
import { Configuration } from '../../../models/config.models';
import { ErrorNormalized } from '../../../models/error.models';

export const authenticationInit = createAction('AUTHENTICATION_INIT')<Configuration['auth']>();

export const authenticationFailure = createAction('AUTHENTICATION_FAILURE')<{
    error: ErrorNormalized;
}>();

export const authenticationSetUser = createAction('AUTHENTICATION_SET_USER')<{
    user: User | null;
}>();

export const authenticationComplete = createAction('AUTHENTICATION_COMPLETE')<{
    user: User | null;
}>();

export const authenticationRedirect = createAction('AUTHENTICATION_REDIRECT')();

export const authenticationLogout = createAction('AUTHENTICATION_LOGOUT')();
