import { PayloadAction } from 'typesafe-actions';
import { ApiResponse } from '../../models/api-response.models';
import { ErrorNormalized } from '../../models/error.models';
import { IdModel } from '../../models/id.models';
import { ItemState } from '../../models/state.models';

const request = <TEntity extends IdModel, TEntityState extends ItemState<TEntity>>(
    state: TEntityState
): TEntityState => ({
    ...state,
    createState: {
        loading: true,
    },
});

const success = <TEntity extends IdModel, TEntityState extends ItemState<TEntity>>(
    state: TEntityState,
    action: PayloadAction<any, ApiResponse<TEntity>>
): TEntityState => ({
    ...state,
    createState: { loading: false },
    items: {
        ...state.items,
        [action.payload.data.id]: action.payload.data,
    },
});

const failure = <TEntity extends IdModel, TEntityState extends ItemState<TEntity>>(
    state: TEntityState,
    action: PayloadAction<any, ErrorNormalized>
): TEntityState => ({
    ...state,
    createState: { loading: false, error: action.payload },
});

export const createItemHelper = {
    request,
    success,
    failure,
};
