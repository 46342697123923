import { useEffect, useState } from 'react';

export const useCloneList = <TModel>(list: TModel[]) => {
    const state = useState<TModel[]>([]);
    const [, setCloneList] = state;
    useEffect(() => {
        setCloneList([...list]);
    }, [list, setCloneList]);

    return state;
};
