import {
    CollectionContent,
    ContentKey,
    SiteContext,
    sortByPriorityThenAddedDate,
} from '@careerhub/content-shared';
import values from 'lodash/values';
import { useMemo } from 'react';
import { EntityMap } from '../../models/state.models';
import { useRootSelector } from './root-selector.hook';

export const orderCollections = (
    collectionMap: EntityMap<CollectionContent>,
    siteId: ContentKey,
    priorityList: string[] | undefined
) =>
    values(collectionMap)
        .filter(v => v !== undefined && v.siteId === siteId)
        .map(v => v!)
        .sort((a, b) => sortByPriorityThenAddedDate(a, b, priorityList || []));

export const useSite = () => {
    return useRootSelector(state => state.sites.items[state.content.activeKey]);
};

export const useCollection = () => {
    const activeId = useRootSelector(state => state.collections.activeId);
    const activeMode = useRootSelector(state => state.collections.activeMode);

    const active = useRootSelector(state =>
        activeId ? state.collections.items[activeId] : undefined
    );

    const state = useRootSelector(state =>
        activeId ? state.collections.itemStates[activeId] : undefined
    );

    return {
        activeId,
        activeMode,
        active,
        state,
    };
};

export const useCollectionList = () => {
    const activeKey = useRootSelector(state => state.content.activeKey);
    const site = useSite();
    const collectionMap = useRootSelector(state => state.collections.items);

    const collectionList = useMemo(() => {
        return orderCollections(collectionMap, activeKey, site?.collectionPriority);
    }, [activeKey, collectionMap, site?.collectionPriority]);

    return collectionList;
};

export const useComponents = () => {
    const { active } = useCollection();
    const componentMap = useRootSelector(state => state.components.items);

    const activeVariantList = useMemo(() => {
        if (!active) {
            return [];
        }

        const priorityList = active.componentPriority;

        return values(componentMap)
            .filter(v => v !== undefined)
            .map(v => v!)
            .filter(v => v.collectionId === active.id)
            .sort((a, b) => sortByPriorityThenAddedDate(a, b, priorityList));
    }, [active, componentMap]);

    return activeVariantList;
};

export const useSections = (variantId: string) => {
    const sectionMap = useRootSelector(state => state.sections.items);
    const priorityList = useRootSelector(
        state => state.components.items[variantId]?.sectionPriority || []
    );

    const variantSectionList = useMemo(() => {
        const items = values(sectionMap)
            .filter(s => !!s)
            .map(s => s!)
            .filter(s => s.componentId === variantId);

        const sorted = items.sort((a, b) => sortByPriorityThenAddedDate(a, b, priorityList));

        return sorted;
    }, [sectionMap, variantId, priorityList]);

    return variantSectionList;
};

export const useActiveContent = () => {
    const site = useSite();
    const state = useRootSelector(state => state.content.contextState);
    const { active } = useCollection();
    const components = useComponents();
    const sections = useRootSelector(state =>
        values(state.sections.items)
            .filter(s => !!s)
            .map(s => s!)
    );

    // this seems like a hack
    const context: SiteContext = {
        site: site || null,
        collections: active ? [active] : [],
        components,
        sections,
    };

    return { context, state };
};
