import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { Icon } from './Icon';

interface Props {
    overlayKey: string;
    size?: SizeProp;
    label?: string;
    linkClassName?: string;
    onDelete: () => void;
    buttonInner?: React.ReactNode;
    containerClassName?: string;
    deleteText?: string;
}

export const DeletePopover: React.FC<Props> = ({
    overlayKey,
    label,
    children,
    size,
    linkClassName,
    onDelete,
    buttonInner,
    containerClassName,
    deleteText,
}) => {
    const [show, setShow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const toggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShow(!show);
    };

    const onDeleteInner = () => {
        onDelete();
        setShow(false);
    };

    return (
        <div ref={ref} className={containerClassName}>
            <Button
                onClick={toggle}
                variant="link"
                title="Delete"
                className={`d-block btn-link-secondary btn-popover-delete btn-no-focus ${
                    linkClassName ? linkClassName : ''
                } ${show ? 'opacity-1' : ''}`}
            >
                {buttonInner || <Icon icon={faTimes} size={size} />}
            </Button>
            <Overlay
                key={overlayKey}
                show={show}
                rootClose
                rootCloseEvent="click"
                onHide={() => setShow(false)}
                container={ref.current}
                target={ref.current}
                placement="left-start"
            >
                <Popover id={overlayKey}>
                    <Popover.Title as="h3">{label || 'Are you sure?'}</Popover.Title>
                    <Popover.Content>
                        {children}
                        <div className="text-right">
                            <Button
                                variant="link"
                                className="text-muted"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </Button>
                            <Button variant="danger" size="sm" onClick={onDeleteInner}>
                                {deleteText || 'Delete'}
                            </Button>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
};
