import { PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import { IdRequestPartialModel } from '../../models/api-request.models';
import { ApiResponse } from '../../models/api-response.models';
import { ErrorNormalized } from '../../models/error.models';
import { IdModel } from '../../models/id.models';
import { ItemState } from '../../models/state.models';

const request = <TEntity extends IdModel, TEntityState extends ItemState<IdModel>>(
    state: TEntityState,
    action: PayloadAction<any, IdRequestPartialModel<TEntity>>
): TEntityState => ({
    ...state,
    itemStates: {
        ...state.itemStates,
        [action.payload.id]: {
            ...state.itemStates[action.payload.id],
            updateState: { loading: true, properties: Object.keys(action.payload) },
        },
    },
});

const success = <TEntity extends IdModel, TEntityState extends ItemState<TEntity>>(
    state: TEntityState,
    action: PayloadAction<any, ApiResponse<TEntity>>
): TEntityState => ({
    ...state,
    items: {
        ...state.items,
        [action.payload.data.id]: action.payload.data,
    },
    itemStates: {
        ...state.itemStates,
        [action.payload.data.id]: {
            ...state.itemStates[action.payload.data.id],
            updateState: { loading: false, properties: undefined },
        },
    },
});

const failure = <TEntity extends IdModel, TEntityState extends ItemState<TEntity>>(
    state: TEntityState,
    action: PayloadMetaAction<any, ErrorNormalized, IdModel>
): TEntityState => ({
    ...state,
    itemStates: {
        ...state.itemStates,
        [action.meta.id]: {
            ...state.itemStates[action.meta.id],
            updateState: { loading: false, error: action.payload, properties: undefined },
        },
    },
});

export const updateItemHelper = {
    request,
    success,
    failure,
};
