import { ActionsObservable } from 'redux-observable';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, skipUntil, take } from 'rxjs/operators';
import { ActionCreator, isActionOf, isOfType } from 'typesafe-actions';
import { RootAction } from '../../features/action.root-index';
import { appInitComplete } from '../../features/initialization/initialization.actions';

export function skipUntilAppInitComplete(action$: Observable<RootAction>) {
    return action$.pipe(skipUntil(action$.pipe(filter(isActionOf(appInitComplete)))));
}

export function delayActionUntilAppInitComplete<TActionCreator extends ActionCreator>(
    action$: ActionsObservable<RootAction>,
    filterOnAction: TActionCreator
): Observable<ReturnType<TActionCreator>> {
    return combineLatest([
        action$.pipe(filter(isActionOf(filterOnAction))),
        action$.pipe(filter(isActionOf(appInitComplete), take(1))),
    ]).pipe(map(result => result[0]));
}

export function delayTypeUntilAppInitComplete<TType extends string>(
    action$: ActionsObservable<RootAction>,
    type: TType
): Observable<RootAction & { type: TType }> {
    return combineLatest([
        action$.pipe(filter(isOfType(type))),
        action$.pipe(filter(isActionOf(appInitComplete), take(1))),
    ]).pipe(map(result => result[0]));
}
