import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useRootSelector } from '../../../hooks/root-selector.hook';

export const CareerHubRibbonItem = () => {
    const careerHubName = useRootSelector(state => state.auth.user?.profile.ch_app_name);
    const careerHubUrl = useRootSelector(state => state.auth.user?.profile.ch_app_url);

    return (
        <OverlayTrigger
            placement="right"
            overlay={props => (
                <Tooltip {...props} id="tooltip-careerhub">
                    Return to {careerHubName || 'CareerHub'}
                </Tooltip>
            )}
        >
            {careerHubUrl ? (
                <a className="mb-3" href={careerHubUrl}>
                    <img src="/careerhub-logo.svg" alt="CareerHub" style={{ maxWidth: '45px' }} />
                </a>
            ) : (
                <img src="/careerhub-logo.svg" alt="CareerHub" style={{ maxWidth: '45px' }} />
            )}
        </OverlayTrigger>
    );
};
