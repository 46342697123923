import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ComponentContent } from '@careerhub/content-shared';
import { useCallback } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { Icon } from '../shared/Icon';
import omit from 'lodash/omit';

interface Props {
    component: ComponentContent;
    onSubmit: (data: ComponentContent) => void;
}

export const ComponentForm: React.FC<Props> = ({ component, onSubmit }) => {
    const itemStates = useRootSelector(state => state.components.itemStates[component.id]);

    const { handleSubmit, register, watch } = useForm<ComponentContent>({
        defaultValues: component,
    });
    const variantValue = watch('variant');

    // horrible, do better
    const onSubmitInner: (data: ComponentContent) => void = useCallback(
        data => {
            let newData = { ...data };

            // this is poor! improve! this should be handled at a form level, don't include data that doesn't
            // exist on the form (this requires a hook-form update)
            if (
                newData.variant !== 'alternate' &&
                newData.variant !== 'alert' &&
                newData.variant !== 'column'
            ) {
                newData = omit(data, 'options');
            }

            onSubmit(newData);
        },
        [onSubmit]
    );

    const onChange = useCallback(() => {
        handleSubmit(onSubmitInner)();
    }, [handleSubmit, onSubmitInner]);

    return (
        <form onSubmit={handleSubmit(onSubmitInner)}>
            {itemStates?.updateState?.error && (
                <Alert variant="danger">
                    <Icon icon={faExclamationTriangle} size="lg">
                        {itemStates?.updateState?.error.message}
                    </Icon>
                </Alert>
            )}
            <Form.Row>
                <Form.Group className="col-sm-6">
                    <label>Variant</label>
                    <select
                        className="form-control"
                        name="variant"
                        ref={register}
                        onChange={onChange}
                    >
                        <option>alternate</option>
                        <option>grid</option>
                        <option>document</option>
                        <option>alert</option>
                        <option>column</option>
                        <option>banner</option>
                    </select>
                </Form.Group>
            </Form.Row>
            {(variantValue === 'alternate' || variantValue === 'column') && (
                <Form.Row>
                    <Form.Group className="col-sm-6">
                        <label>Text Size</label>
                        <select
                            className="form-control"
                            name="options.textSize"
                            ref={register}
                            onChange={onChange}
                        >
                            <option value="">large</option>
                            <option value="md">normal</option>
                        </select>
                    </Form.Group>
                    {variantValue === 'alternate' && (
                        <Form.Group className="col-sm-6">
                            <label>Image Size</label>
                            <select
                                className="form-control"
                                name="options.imageSize"
                                ref={register}
                                onChange={onChange}
                            >
                                <option value="">large</option>
                                <option value="sm">small</option>
                            </select>
                        </Form.Group>
                    )}
                </Form.Row>
            )}
            {variantValue === 'alert' && (
                <Form.Row>
                    <Form.Group className="col-sm-6">
                        <label>Type</label>
                        <select
                            className="form-control"
                            name="options.alertType"
                            ref={register}
                            onChange={onChange}
                        >
                            <option value="info">info</option>
                            <option value="warning">warning</option>
                            <option value="danger">danger</option>
                        </select>
                    </Form.Group>
                </Form.Row>
            )}
        </form>
    );
};
