import { push } from 'connected-react-router';
import values from 'lodash/values';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { routePathCreator } from '../../../ui/components/routes/RouteList';
import * as actions from './collection.actions';
import { componentCreateAsync } from '../components/component.actions';
import { asyncEpicStandard, asyncEpicBase } from '../../helpers/epics/async-epic.helper';
import { RootEpic } from '../epic.root-index';

export const collectionCreateAsyncEpic = asyncEpicStandard(
    actions.collectionCreateAsync,
    ({ api }, { payload }) => api.collection.create(payload)
);

export const collectionCreateDefaultEpic: RootEpic = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(actions.collectionCreateDefault)),
        mergeMap(() => {
            const activeKey = state$.value.content.activeKey;
            const newCollection = services.helpers.collection.generate(activeKey, state$.value);

            return of(actions.collectionCreateAsync.request(newCollection));
        })
    );
};

export const collectionDeleteAsyncEpic = asyncEpicBase(
    actions.collectionDeleteAsync,
    ({ api }, { payload }) => api.collection.del(payload),
    {
        success: result => actions.collectionDeleteAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.collectionDeleteAsync.failure(error, requestAction.payload),
    }
);

export const collectionUpdateAsyncEpic = asyncEpicBase(
    actions.collectionUpdateAsync,
    ({ api }, { payload }) => api.collection.update(payload),
    {
        success: result => actions.collectionUpdateAsync.success(result.json),
        failure: (error, requestAction) =>
            actions.collectionUpdateAsync.failure(error, requestAction.payload),
    }
);

export const collectionOnCreateSetActiveEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.collectionCreateAsync.success)),
        mergeMap(action => {
            const path = routePathCreator.content({
                contentKey: state$.value.content.activeKey,
                tenant: state$.value.content.tenant,
                collectionId: action.payload.data.id,
            });
            return of(push(path));
        })
    );
};

export const collectionOnCreateCreateVariantEpic: RootEpic = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.collectionCreateAsync.success)),
        mergeMap(action =>
            of(
                componentCreateAsync.request({
                    variant: 'alternate',
                    collectionId: action.payload.data.id,
                })
            )
        )
    );
};

// this should really take the order into account, but it doesn't
export const collectionOnDeleteSetActive: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(isActionOf(actions.collectionDeleteAsync.success)),
        mergeMap(() => {
            const collection = values(state$.value.collections.items)
                .filter(i => i?.siteId === state$.value.content.activeKey)
                .reverse()[0];

            return of(actions.collectionSetActive({ id: collection?.id }));
        })
    );
};
