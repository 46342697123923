import { LocationChangeAction } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { Location } from 'history';
import { isArray } from 'lodash';
import { RootState } from '../../features/reducer.root-index';

export const getRouteMatch = <
    TParams extends { [K in keyof TParams]?: string }
>(
    location: Location<any>,
    path: string | string[]
) => {
    return matchPath<TParams>(location.pathname, path);
};

export const isRouteMatch = (
    location: Location<any>,
    paths: string | string[]
) =>
    (isArray(paths) ? paths : [paths]).some(
        path => !!getRouteMatch(location, path)
    );

export const isLocationRouteMatch =
    (paths: string | string[]) => (action: LocationChangeAction<any>) =>
        isRouteMatch(action.payload.location, paths);

export const isStateRouteMatch =
    (paths: string | string[]) => (state: RootState) =>
        isRouteMatch(state.router.location, paths);
