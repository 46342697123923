import { Spinner } from 'react-bootstrap';
import { LoaderState } from '../../../models/error.models';

interface Props {
    state: LoaderState;
    size?: 'sm';
    spinnerClassName?: string;
    spinnerStyle?: React.CSSProperties;
    overrideStateLoading?: boolean;
}

export const LoaderSpinner: React.FC<Props> = ({
    state,
    children,
    size,
    spinnerClassName,
    overrideStateLoading,
}) => {
    const loading = overrideStateLoading !== undefined ? overrideStateLoading : state.loading;

    return loading ? (
        <Spinner animation="border" size={size} className={spinnerClassName} />
    ) : (
        <>{children}</>
    );
};
