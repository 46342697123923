import { createReducer } from 'typesafe-actions';
import { Configuration } from '../../../models/config.models';
import * as actions from './initialization.actions';

export type InitializationState = Readonly<{
    started: boolean;
    completed: boolean;
    config: Configuration;
}>;

const initialState: InitializationState = {
    started: false,
    completed: false,
    config: {} as any,
};

const initializationReducer = createReducer(initialState)
    .handleAction(actions.appInit, state => ({
        ...state,
        started: true,
    }))
    .handleAction(actions.appInitComplete, state => ({
        ...state,
        completed: true,
    }))
    .handleAction(actions.configurationInit, (state, action) => ({
        ...state,
        config: action.payload,
    }));

export default initializationReducer;
