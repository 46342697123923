import { faColumns, faEye, faList } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { layoutViewMode } from '../../../logic/features/layout/layout.actions';
import { LayoutViewMode } from '../../../logic/features/layout/layout.reducer';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { Icon } from '../shared/Icon';

export const showEditView: LayoutViewMode[] = ['all', 'edit'];
export const showPreviewView: LayoutViewMode[] = ['all', 'preview'];

export const PreviewNav = () => {
    const dispatch = useDispatch();
    const { viewMode } = useRootSelector(state => state.layout);

    const onViewChange = useCallback(
        (newValue: LayoutViewMode) => {
            if (newValue === viewMode) {
                return;
            }
            dispatch(layoutViewMode({ viewMode: newValue }));
        },
        [dispatch, viewMode]
    );

    return (
        <ButtonGroup size="sm" className="mr-3">
            <Button
                className="btn-no-focus"
                title="edit"
                variant={viewMode === 'edit' ? 'primary' : 'outline-primary'}
                onClick={() => onViewChange('edit')}
            >
                <Icon icon={faList} />
                <span className="sr-only">edit</span>
            </Button>
            <Button
                className="btn-no-focus"
                title="both"
                variant={viewMode === 'all' ? 'primary' : 'outline-primary'}
                onClick={() => onViewChange('all')}
            >
                <Icon icon={faColumns} />
                <span className="sr-only">both</span>
            </Button>
            <Button
                className="btn-no-focus"
                title="preview"
                variant={viewMode === 'preview' ? 'primary' : 'outline-primary'}
                onClick={() => onViewChange('preview')}
            >
                <Icon icon={faEye} />
                <span className="sr-only">preview</span>
            </Button>
        </ButtonGroup>
    );
};
