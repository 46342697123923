import { Container } from 'react-bootstrap';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { SideNav } from '../nav/SideNav';
import { TopNav } from '../nav/TopNav';

interface Props {
    sideNav?: React.ReactNode;
    topNav?: React.ReactNode;
}

export const LayoutContainer: React.FC<Props> = ({ sideNav, topNav, children }) => {
    const { sideNavCollapsed } = useRootSelector(state => state.layout);

    return (
        <Container fluid className="p-0">
            <div className="d-flex">
                <div className={`layout-main-side ${sideNavCollapsed ? 'collapsed' : ''}`}>
                    {sideNav || <SideNav />}
                </div>
                <div className={`layout-main-content ${sideNavCollapsed ? 'extended' : ''}`}>
                    <div>{topNav || <TopNav />}</div>
                    <div className="position-relative">
                        <div className="overflow-hidden position-fixed layout-main-content-inner">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};
