import { CollectionContent, CollectionContentCreate } from '@careerhub/content-shared';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse } from '../../../models/api-response.models';
import { ErrorNormalized } from '../../../models/error.models';
import { IdModel } from '../../../models/id.models';

export const collectionCreateAsync = createAsyncAction(
    'COLLECTION_CREATE:REQUEST',
    'COLLECTION_CREATE:SUCCESS',
    'COLLECTION_CREATE:FAILURE'
)<CollectionContentCreate, ApiResponse<CollectionContent>, ErrorNormalized>();

export const collectionUpdateAsync = createAsyncAction(
    'COLLECTION_UPDATE:REQUEST',
    'COLLECTION_UPDATE:SUCCESS',
    'COLLECTION_UPDATE:FAILURE'
)<
    IdRequestPartialModel<CollectionContent>,
    ApiResponse<CollectionContent>,
    [ErrorNormalized, IdModel]
>();

export const collectionDeleteAsync = createAsyncAction(
    'COLLECTION_DELETE:REQUEST',
    'COLLECTION_DELETE:SUCCESS',
    'COLLECTION_DELETE:FAILURE'
)<IdModel, ApiResponse<CollectionContent>, [ErrorNormalized, IdModel]>();

export const collectionSetActive = createAction('COLLECTION:SET_ACTIVE')<Partial<IdModel>>();
export const collectionModeSetActive = createAction('COLLECTION_MODE:SET_ACTIVE')<{
    mode: 'view' | 'edit';
}>();

export const collectionCreateDefault = createAction('COLLECTION_CREATE')();
