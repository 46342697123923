import { SiteContextConfig, SiteDisplay } from '@careerhub/content-shared';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useContentLayout } from '../../hooks/content-layout.hook';
import { useActiveContent, useCollection } from '../../hooks/content.hook';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { CollectionAdd } from '../collections/CollectionAdd';
import { CollectionList } from '../collections/CollectionList';
import { ComponentList } from '../component-variants/ComponentList';
import { ContentTopNav } from '../nav/ContentTopNav';
import { showEditView, showPreviewView } from '../nav/PreviewNav';
import { SideNav } from '../nav/SideNav';
import { Icon } from '../shared/Icon';
import { LoaderPlaceholder } from '../shared/LoaderPlaceholder';

import DOMPurify from 'dompurify';
import { useCallback, useMemo } from 'react';

export const useDomPurify = () => {
    const sanitizeHtml = useCallback(source => {
        return DOMPurify.sanitize(source, {
            USE_PROFILES: { html: true },
            ADD_ATTR: ['target'],
        });
    }, []);

    return {
        sanitizeHtml,
    };
};

export const ContentPage = () => {
    const { context, state } = useActiveContent();
    const { active, activeId } = useCollection();
    const { contextState } = useRootSelector(state => state.content);
    const { sideNavCollapsed, viewMode } = useRootSelector(
        state => state.layout
    );
    const { heightRef, heightStyle, widthRef, widthStyle } = useContentLayout();

    const { sanitizeHtml } = useDomPurify();
    const config: SiteContextConfig = useMemo(() => {
        return {
            sanitizeHtml,
        };
    }, [sanitizeHtml]);

    return (
        <Container fluid className="p-0">
            <div className="d-flex">
                <div
                    ref={widthRef}
                    className={`layout-main-side ${
                        sideNavCollapsed ? 'collapsed' : ''
                    }`}
                >
                    <SideNav>
                        <LoaderPlaceholder state={contextState}>
                            <CollectionList />
                            <CollectionAdd />
                        </LoaderPlaceholder>
                    </SideNav>
                </div>
                <div
                    className={`layout-main-content ${viewMode} ${
                        sideNavCollapsed ? 'extended' : ''
                    }`}
                >
                    <div ref={heightRef}>
                        <ContentTopNav />
                    </div>
                    {!active && activeId && !state.loading && (
                        <div className="mx-5 my-3">
                            <Alert variant="danger">
                                <Icon icon={faExclamationTriangle} size="lg">
                                    Collection not found
                                </Icon>
                            </Alert>
                        </div>
                    )}
                    {active && (
                        <div className="position-relative">
                            <div
                                className="overflow-hidden position-fixed layout-main-content-inner"
                                style={{
                                    height: heightStyle,
                                    width: widthStyle,
                                }}
                            >
                                <Row style={{ height: heightStyle }}>
                                    {showEditView.includes(viewMode) && (
                                        <Col
                                            xs={viewMode === 'all' ? 6 : 12}
                                            xl={viewMode === 'all' ? 5 : 12}
                                            className="h-100 test-test"
                                        >
                                            <div className="overflow-y h-100">
                                                <div className="px-2 px-md-3 pt-3 pb-5 mb-5">
                                                    <ComponentList />
                                                    {/* <TestSockets /> */}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                    {showPreviewView.includes(viewMode) && (
                                        <Col
                                            xs={viewMode === 'all' ? 6 : 12}
                                            xl={viewMode === 'all' ? 7 : 12}
                                            style={{ height: heightStyle }}
                                        >
                                            <div
                                                className="overflow-y overflow-x-hidden"
                                                style={{ height: heightStyle }}
                                            >
                                                <div
                                                    className={
                                                        viewMode === 'all'
                                                            ? 'scale-50 scale-xl-66 pb-5'
                                                            : undefined
                                                    }
                                                >
                                                    {context &&
                                                        !state.loading &&
                                                        active && (
                                                            <SiteDisplay
                                                                context={
                                                                    context
                                                                }
                                                                activeCollectionId={
                                                                    active.id
                                                                }
                                                                config={config}
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};
