import * as sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'babel-polyfill';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { executeConfigurationInit } from './config/configuration';
import { routerHistory } from './logic/features/reducer.root-index';
import configureStore from './logic/store.index';
import { storeRegistry } from './logic/store.registry';
import './styles/index.scss';
import { MasterErrorBoundry } from './ui/components/layout/MasterErrorBoundry';
import { RouteList } from './ui/components/routes/RouteList';

const store = configureStore();
storeRegistry.store(store);

const env = process.env.REACT_APP_ENV;
executeConfigurationInit(env);

// master is production, it's based off the git branch, see the .env file
if (env === 'staging' || env === 'master') {
    process.env.REACT_APP_SENTRY_DSN &&
        sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            release: process.env.REACT_APP_COMMIT_REF,
            normalizeDepth: 10,
            environment: env,
            autoSessionTracking: true,
            integrations: [new Integrations.BrowserTracing()],
        });
}

ReactDOM.render(
    //  <React.StrictMode>
    <Provider store={store}>
        <MasterErrorBoundry>
            <ConnectedRouter history={routerHistory}>
                <RouteList />
            </ConnectedRouter>
        </MasterErrorBoundry>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);
