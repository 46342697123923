import { CollectionContent, CollectionContentCreate } from '@careerhub/content-shared';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse, ObsApiExpected } from '../../../models/api-response.models';
import { IdModel } from '../../../models/id.models';
import { fetchHelper } from '../../helpers/fetch.helper';
import { generateUrl } from '../../helpers/url.helper';

export const create = (
    collection: CollectionContentCreate
): ObsApiExpected<ApiResponse<CollectionContent>> =>
    fetchHelper.post(generateUrl(['collections']), collection);

export const update = (
    collection: IdRequestPartialModel<CollectionContent>
): ObsApiExpected<ApiResponse<CollectionContent>> =>
    fetchHelper.put(generateUrl(['collections', collection.id]), collection);

export const del = (request: IdModel): ObsApiExpected<ApiResponse<CollectionContent>> =>
    fetchHelper.del(generateUrl(['collections', request.id]));
