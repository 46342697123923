import * as jwt from '../helpers/jwt.helper';
import * as auth from './authentication/authentication.service';
import * as collectionHelper from './collections/collection.helper';
import * as collection from './collections/collection.service';
import * as component from './components/component.service';
import * as content from './local-storage/content-local.service';
import * as section from './sections/section.service';
import * as site from './sites/site.service';

const services = {
    auth,
    api: {
        site,
        collection,
        component,
        section,
    },
    helpers: {
        collection: collectionHelper,
        jwt,
    },
    local: {
        content,
    },
};

export type Services = typeof services;
export default services;
