import { CollectionContent } from '@careerhub/content-shared';
import keyBy from 'lodash/keyBy';
import { createReducer } from 'typesafe-actions';
import { ItemState } from '../../../models/state.models';
import * as actions from './collection.actions';
import { siteContextAsync } from '../sites/site.actions';
import { createItemHelper } from '../../reducer-handlers/create-item.helper';
import { initialStateHelper } from '../../helpers/initial-state.helper';
import { deleteItemHelper } from '../../reducer-handlers/delete-item.helper';
import { updateItemHelper } from '../../reducer-handlers/update-item.helper';

export type CollectionState = ItemState<CollectionContent> & {
    activeMode: 'view' | 'edit';
};

const initialState: CollectionState = {
    ...initialStateHelper.itemState(),
    activeMode: 'view',
};

const collectionReducer = createReducer(initialState)
    .handleAction(siteContextAsync.success, (state, action) => ({
        ...state,
        items: {
            ...state.items,
            ...keyBy(action.payload.data.collections, c => c.id),
        },
    }))
    .handleAction(actions.collectionCreateAsync.request, createItemHelper.request)
    .handleAction(actions.collectionCreateAsync.success, (state, action) => ({
        ...createItemHelper.success(state, action),
        activeId: action.payload.data.id,
        activeMode: 'edit',
    }))
    .handleAction(actions.collectionCreateAsync.failure, createItemHelper.failure)

    .handleAction(actions.collectionDeleteAsync.request, deleteItemHelper.request)
    .handleAction(actions.collectionDeleteAsync.success, deleteItemHelper.success)
    .handleAction(actions.collectionDeleteAsync.failure, deleteItemHelper.failure)

    .handleAction(actions.collectionUpdateAsync.request, (state, action) => ({
        ...updateItemHelper.request(state, action),
        activeMode: 'view',
    }))
    .handleAction(actions.collectionUpdateAsync.success, updateItemHelper.success)
    .handleAction(actions.collectionUpdateAsync.failure, updateItemHelper.failure)

    .handleAction(actions.collectionSetActive, (state, action) => ({
        ...state,
        activeId: action.payload.id,
        activeMode: 'view',
    }))
    .handleAction(actions.collectionModeSetActive, (state, action) => ({
        ...state,
        activeMode: action.payload.mode,
    }));

export default collectionReducer;
