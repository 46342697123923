import { Log, UserManager } from 'oidc-client';
import { from } from 'rxjs';
import { Configuration } from '../../../models/config.models';

let manager: UserManager;

export const init = (config: Configuration['auth']) => {
    manager = new UserManager({
        ...config,
        redirect_uri: window.location.origin,
        response_type: 'token id_token',
        scope: [
            'openid',
            'ch_app',
            'ch.Admin.Personal.Details',
            'email',
            'profile',
            'content.*',
        ].join(' '),
        // the user endpoint actually causes the name to be set twice, since the user endpoint returns as a "name"
        loadUserInfo: false,
        automaticSilentRenew: false,
        monitorSession: false, // this causes seriously wack behavior so I'm disabling it
    });

    Log.logger = console;
    Log.level = Log.INFO;
};

export const signinRedirect = (tenant?: string) => {
    const args = {
        acr_values: tenant ? `idp:CareerHubAdmin:${tenant}` : 'CareerHubAdmin',
        state: { redirectUrl: window.location.pathname + window.location.search },
    };

    return from(manager.signinRedirect(args));
};

export const signoutRedirect = () => {
    return from(manager.signoutRedirect());
};

export const signinRedirectCallback = () => {
    return from(manager.signinRedirectCallback());
};

export const getUser = () => from(manager.getUser());

export const clearStaleState = () => from(manager.clearStaleState());

export const removeUser = () => from(manager.removeUser());
