import { ComponentContent, ComponentContentCreate } from '@careerhub/content-shared';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse, ObsApiExpected } from '../../../models/api-response.models';
import { IdModel } from '../../../models/id.models';
import { fetchHelper } from '../../helpers/fetch.helper';
import { generateUrl } from '../../helpers/url.helper';
export const create = (
    component: ComponentContentCreate
): ObsApiExpected<ApiResponse<ComponentContent>> =>
    fetchHelper.post(generateUrl(['components']), component);

export const update = (
    component: IdRequestPartialModel<ComponentContent>
): ObsApiExpected<ApiResponse<ComponentContent>> =>
    fetchHelper.put(generateUrl(['components', component.id]), component);

export const del = (request: IdModel): ObsApiExpected<ApiResponse<ComponentContent>> =>
    fetchHelper.del(generateUrl(['components', request.id]));
