import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { LayoutContainer } from '../layout/LayoutContainer';
import { SideNav } from '../nav/SideNav';
import { TopNav } from '../nav/TopNav';

export const ResourcePage = () => {
    return (
        <LayoutContainer
            sideNav={
                <SideNav>
                    <ListGroup variant="flush">
                        <ListGroupItem variant="light" action active className="no-focus">
                            Images
                        </ListGroupItem>
                    </ListGroup>
                </SideNav>
            }
            topNav={
                <TopNav>
                    <h1 className="mb-0 d-inline text-dark text-long-title">Resources</h1>
                </TopNav>
            }
        >
            <div className="p-3">
                <p>Metadata on resources, and an example of the resource?</p>
            </div>
        </LayoutContainer>
    );
};
