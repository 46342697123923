import { SectionContent, SectionContentCreate } from '@careerhub/content-shared';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { IdRequestPartialModel } from '../../../models/api-request.models';
import { ApiResponse } from '../../../models/api-response.models';
import { ErrorNormalized } from '../../../models/error.models';
import { IdModel, SectionImageRequest } from '../../../models/id.models';

export const sectionCreateAsync = createAsyncAction(
    'SECTION_CREATE:REQUEST',
    'SECTION_CREATE:SUCCESS',
    'SECTION_CREATE:FAILURE'
)<SectionContentCreate, ApiResponse<SectionContent>, [ErrorNormalized, SectionContentCreate]>();

export const sectionUpdateAsync = createAsyncAction(
    'SECTION_UPDATE:REQUEST',
    'SECTION_UPDATE:SUCCESS',
    'SECTION_UPDATE:FAILURE'
)<IdRequestPartialModel<SectionContent>, ApiResponse<SectionContent>, [ErrorNormalized, IdModel]>();

export const sectionDeleteAsync = createAsyncAction(
    'SECTION_DELETE:REQUEST',
    'SECTION_DELETE:SUCCESS',
    'SECTION_DELETE:FAILURE'
)<IdModel, ApiResponse<SectionContent>, [ErrorNormalized, IdModel]>();

export const sectionImageUploadAsync = createAsyncAction(
    'SECTION_IMAGE_CREATE:REQUEST',
    'SECTION_IMAGE_CREATE:SUCCESS',
    'SECTION_IMAGE_CREATE:FAILURE'
)<SectionImageRequest, ApiResponse<SectionContent>, [ErrorNormalized, SectionImageRequest]>();

export const sectionImageDeleteAsync = createAsyncAction(
    'SECTION_IMAGE_DELETE:REQUEST',
    'SECTION_IMAGE_DELETE:SUCCESS',
    'SECTION_IMAGE_DELETE:FAILURE'
)<IdModel, ApiResponse<SectionContent>, [ErrorNormalized, IdModel]>();

export const sectionSetActive = createAction('SECTION:SET_ACTIVE')<Partial<IdModel>>();
export const sectionSetHover = createAction('SECTION:HOVER')<Partial<IdModel>>();
