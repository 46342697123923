import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { LoaderState } from '../../../models/error.models';
import { Icon } from './Icon';

interface Props {
    state?: LoaderState;
    onProperties?: string[];
}

export const LoaderPlaceholder: React.FC<Props> = ({ state, children, onProperties }) => {
    const isLoading = useMemo(() => {
        if (!state?.loading) {
            return false;
        }

        return onProperties ? state.properties?.some(p => onProperties.includes(p)) : true;
    }, [onProperties, state]);

    if (state?.error) {
        return (
            <div className="text-danger">
                <Icon icon={faExclamationTriangle}>{state.error.message}</Icon>
            </div>
        );
    }

    return isLoading ? (
        <div className="text-input__loading">
            <div className="text-input__loading--line big rounded"></div>
        </div>
    ) : (
        <>{children}</>
    );
};
