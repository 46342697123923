import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { useCallback, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { reorder } from '../../../logic/helpers/array.helper';
import { useCloneList } from '../../hooks/clone-list.hook';
import { useCollectionList } from '../../hooks/content.hook';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { siteUpdateAsync } from '../../../logic/features/sites/site.actions';
import { routePathCreator } from '../routes/RouteList';
import { DragWrapper, DragWrapperDropResult } from '../shared/DragWrapper';
import { Icon } from '../shared/Icon';

export const CollectionList = () => {
    const { activeKey, tenant } = useRootSelector(state => state.content);
    const activeCollectionId = useRootSelector(
        state => state.collections.activeId
    );
    const activeCollectionList = useCollectionList();
    const activeCollectionIds = useMemo(
        () => activeCollectionList.map(a => a.id),
        [activeCollectionList]
    );
    const [cloneList, setCloneList] = useCloneList(activeCollectionList);

    const dispatch = useDispatch();

    const onSelectCollection = (id: string) => {
        if (activeCollectionId === id) {
            return;
        }

        const path = routePathCreator.content({
            contentKey: activeKey,
            tenant,
            collectionId: id,
        });

        dispatch(push(path));
    };

    const onDragEnd = (result: DragWrapperDropResult) => {
        if (!result.destination) {
            return;
        }

        const toSave = reorder(
            activeCollectionIds,
            result.source.index,
            result.destination.index
        );
        dispatch(
            siteUpdateAsync.request({
                id: activeKey,
                collectionPriority: toSave,
            })
        );
        setCloneList(cloneList =>
            toSave.map(id => cloneList.find(c => c.id === id)!)
        );
    };

    const getItemClassName = useCallback(
        (id: string, index: number) =>
            `d-flex list-group-item-action-fake mb-2 ${
                activeCollectionId === id ? 'active' : ''
            } ${index === 0 ? 'border-top-0' : ''}`,
        [activeCollectionId]
    );

    return (
        <ListGroup variant="flush" className="border-lightfade border-bottom">
            <DragWrapper onDragEnd={onDragEnd} droppableId="collection-list">
                {cloneList.map((collection, index) => (
                    <DragWrapper.Inner
                        key={collection.id}
                        draggableId={collection.id}
                        index={index}
                        itemClassName={getItemClassName(collection.id, index)}
                    >
                        {provided => (
                            <>
                                <div
                                    className="p-2"
                                    {...provided.dragHandleProps}
                                >
                                    <Icon
                                        className="fa-w-14"
                                        icon={faGripVertical}
                                    />
                                </div>
                                <button
                                    onClick={() =>
                                        onSelectCollection(collection.id)
                                    }
                                    className="w-100 list-group-item-inner-button px-0 py-2"
                                >
                                    {collection.title}
                                </button>
                            </>
                        )}
                    </DragWrapper.Inner>
                ))}
            </DragWrapper>
        </ListGroup>
    );
};
