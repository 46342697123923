import { SectionContent } from '@careerhub/content-shared';
import keyBy from 'lodash/keyBy';
import { createReducer } from 'typesafe-actions';
import { LoaderState } from '../../../models/error.models';
import { ItemState } from '../../../models/state.models';
import { layoutViewMode } from '../layout/layout.actions';
import * as actions from './section.actions';
import { siteContextAsync } from '../sites/site.actions';
import { createItemHelper } from '../../reducer-handlers/create-item.helper';
import { initialStateHelper } from '../../helpers/initial-state.helper';
import { deleteItemHelper } from '../../reducer-handlers/delete-item.helper';
import { updateItemHelper } from '../../reducer-handlers/update-item.helper';

export type SectionState = ItemState<SectionContent> & {
    imageStates: { [key: string]: LoaderState };
    activeSectionId?: string;
    hoverSectionId?: string;
    creatingForComponentIds: string[];
};

const initialState: SectionState = {
    ...initialStateHelper.itemState(),
    imageStates: {},
    creatingForComponentIds: [],
};

const sectionReducer = createReducer(initialState)
    .handleAction(siteContextAsync.success, (state, action) => ({
        ...state,
        items: {
            ...state.items,
            ...keyBy(action.payload.data.sections, c => c.id),
        },
    }))
    .handleAction(actions.sectionCreateAsync.request, (state, action) => ({
        ...createItemHelper.request(state),
        creatingForComponentIds: [...state.creatingForComponentIds, action.payload.componentId],
    }))
    .handleAction(actions.sectionCreateAsync.failure, (state, action) => ({
        ...createItemHelper.failure(state, action),
        creatingForComponentIds: state.creatingForComponentIds.filter(
            i => i !== action.meta.componentId
        ),
    }))
    .handleAction(actions.sectionCreateAsync.success, (state, action) => ({
        ...createItemHelper.success(state, action),
        activeSectionId: action.payload.data.id,
        creatingForComponentIds: state.creatingForComponentIds.filter(
            i => i !== action.payload.data.componentId
        ),
    }))

    .handleAction(actions.sectionDeleteAsync.request, deleteItemHelper.request)
    .handleAction(actions.sectionDeleteAsync.success, deleteItemHelper.success)
    .handleAction(actions.sectionDeleteAsync.failure, deleteItemHelper.failure)

    .handleAction(actions.sectionUpdateAsync.request, updateItemHelper.request)
    .handleAction(actions.sectionUpdateAsync.success, updateItemHelper.success)
    .handleAction(actions.sectionUpdateAsync.failure, updateItemHelper.failure)

    // section image upload actions
    .handleAction(actions.sectionImageUploadAsync.request, (state, action) => ({
        ...state,
        imageStates: {
            ...state.imageStates,
            [action.payload.sectionId]: {
                loading: true,
            },
        },
    }))
    .handleAction(actions.sectionImageUploadAsync.failure, (state, action) => ({
        ...state,
        imageStates: {
            ...state.imageStates,
            [action.meta.sectionId]: {
                loading: false,
                error: action.payload,
            },
        },
    }))
    .handleAction(actions.sectionImageUploadAsync.success, (state, action) => ({
        ...updateItemHelper.success(state, action),
        imageStates: {
            ...state.imageStates,
            [action.payload.data.id]: {
                loading: false,
            },
        },
    }))
    // section image delete actions
    .handleAction(actions.sectionImageDeleteAsync.request, (state, action) => ({
        ...state,
        imageStates: {
            ...state.imageStates,
            [action.payload.id]: {
                loading: true,
            },
        },
    }))
    .handleAction(actions.sectionImageDeleteAsync.failure, (state, action) => ({
        ...state,
        imageStates: {
            ...state.imageStates,
            [action.meta.id]: {
                loading: false,
                error: action.payload,
            },
        },
    }))
    .handleAction(actions.sectionImageDeleteAsync.success, (state, action) => ({
        ...updateItemHelper.success(state, action),
        imageStates: {
            ...state.imageStates,
            [action.payload.data.id]: {
                loading: false,
            },
        },
    }))
    .handleAction(actions.sectionSetActive, (state, action) => ({
        ...state,
        activeSectionId: action.payload.id,
    }))
    .handleAction(actions.sectionSetHover, (state, action) => ({
        ...state,
        hoverSectionId: action.payload.id,
    }))
    .handleAction(layoutViewMode, (state, action) => ({
        ...state,
        activeSectionId: action.payload.viewMode === 'preview' ? undefined : state.activeSectionId,
    }));

export default sectionReducer;
