import * as sentry from '@sentry/react';
import { RootState } from '../features/reducer.root-index';

export const sentryEnhancer = sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state: RootState) => {
        const {
            auth: { user },
        } = state;

        if (user) {
            scope.setUser({
                id: user.profile.sub,
                email: user.profile.email,
                username: `${user.profile.firstName} ${user.profile.lastName}`,
            });
        }
    },
});
