import { faList } from '@fortawesome/free-solid-svg-icons';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { useRootSelector } from '../../../hooks/root-selector.hook';
import { routePathCreator, routePaths } from '../../routes/RouteList';
import { Icon } from '../../shared/Icon';

export const ContentRibbonItem = () => {
    const { tenant, activeKey: contentKey } = useRootSelector(state => state.content);
    const match = useRouteMatch(routePaths.siteContent);

    return (
        <>
            <OverlayTrigger
                placement="right"
                overlay={props => (
                    <Tooltip {...props} id="tooltip-content">
                        Content
                    </Tooltip>
                )}
            >
                <Link
                    to={routePathCreator.content({ tenant, contentKey })}
                    className={`btn btn-link-light py-2 nav-icon-button ${match ? 'active' : ''}`}
                >
                    <Icon icon={faList} size="lg" className="fa-w-18" />
                </Link>
            </OverlayTrigger>
        </>
    );
};
