import { CollectionPageTitle } from '../collections/CollectionPageTitle';
import { PreviewNav } from './PreviewNav';
import { TopNav } from './TopNav';

export const ContentTopNav = () => {
    return (
        <TopNav>
            <div className="d-flex align-items-center w-100">
                <div>
                    <CollectionPageTitle />
                </div>
                <div className="ml-auto">
                    <PreviewNav />
                </div>
            </div>
        </TopNav>
    );
};
