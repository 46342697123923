import React from 'react';
import { ErrorInfo, PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { logError } from '../../../logic/helpers/sentry.helper';

export class MasterErrorBoundry extends React.Component<
    PropsWithChildren<Record<string, unknown>>,
    { errorId: string | undefined }
> {
    constructor(props: any) {
        super(props);
        this.state = { errorId: undefined };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const errorId = logError(error, { ...errorInfo });
        this.setState({ errorId: errorId });
    }

    public render() {
        if (this.state.errorId) {
            return (
                <Modal show={true} animation={false} className="border-danger">
                    <h2>An Unexpected error occurred.</h2>
                </Modal>
            );
        }

        return this.props.children || null;
    }
}
