import { combineEpics, Epic } from 'redux-observable';
import * as auth from './authentication/authentication.epics';
import * as collection from './collections/collection.epics';
import * as content from './content/content.epics';
import * as init from './initialization/initialization.epics';
import * as section from './sections/section.epics';
import * as site from './sites/site.epics';
import * as variant from './components/component.epics';
import { RootAction } from './action.root-index';
import { RootState } from './reducer.root-index';
import { Services } from './service.root-index';

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

export default combineEpics(
    ...Object.values(init),
    ...Object.values(auth),
    ...Object.values(content),
    ...Object.values(site),
    ...Object.values(collection),
    ...Object.values(variant),
    ...Object.values(section)
);
