import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Alert, Button, Spinner } from 'react-bootstrap';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { Icon } from '../shared/Icon';

// todo: cleanup, maybe add logging?
export const AuthenticationGuard: React.FC = ({ children }) => {
    const { complete, user, error } = useRootSelector(state => state.auth);
    const { keys, tenant } = useRootSelector(state => state.content);

    const careerHubName = useRootSelector(
        state => state.auth.user?.profile.ch_app_name
    );
    const careerHubUrl = useRootSelector(
        state => state.auth.user?.profile.ch_app_url
    );

    // this will only be true once the auth_init action is fired
    if (!complete) {
        return (
            <div className="text-center p-5 text-muted">
                <Spinner animation="border" size="sm" className="mr-2" />
                Authenticating...
            </div>
        );
    }

    // if the error is set, it's usually an configuration error (or identity could be down)
    if (error) {
        return (
            <div className="text-center p-5 d-flex ">
                <div className="mx-auto">
                    <Alert variant="danger">
                        <Icon size="lg" icon={faExclamationTriangle}>
                            {error.message}
                        </Icon>
                    </Alert>
                    <div>
                        Click{' '}
                        <Button
                            onClick={() =>
                                window.location.replace(
                                    window.location.pathname
                                )
                            }
                            variant="link px-0 border-0"
                        >
                            here
                        </Button>{' '}
                        to try again.
                    </div>
                </div>
            </div>
        );
    }

    // if the user doesn't exist at this point, the user ain't authenticated
    if (!user) {
        return (
            <div className="text-center p-5 d-flex">
                <Alert variant="danger" className="mx-auto">
                    <Icon size="lg" icon={faExclamationTriangle}>
                        Unauthenticated User
                    </Icon>
                </Alert>
            </div>
        );
    }

    // if the user gets to this point, they are authenticated (a careerhub admin) but
    // they don't have any "Content" permissions
    if (keys.length === 0) {
        return (
            <div className="text-center p-5 d-flex">
                <div className="mx-auto">
                    <Alert variant="danger" className="mx-auto">
                        <Icon size="lg" icon={faExclamationTriangle}>
                            You do not have the correct permissions to view this
                            site.
                        </Icon>
                    </Alert>
                    <div>
                        <span>Click </span>
                        <Button
                            onClick={() => window.location.reload()}
                            variant="link px-0 border-0"
                        >
                            here
                        </Button>
                        <span> to try again.</span>
                    </div>
                    {careerHubUrl && (
                        <div>
                            <span>Click </span>
                            <a href={careerHubUrl}>here</a>
                            <span>
                                {' '}
                                to return to {careerHubName || 'CareerHub'}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    // finally, if the user doesn't have the tenant scope, well, there ain't much we
    // can do about it, so we just have to show an error.
    // hitting this would indicate that something has not been configured correctly.
    if (!tenant) {
        return (
            <div className="text-center p-5 d-flex">
                <Alert variant="danger" className="mx-auto">
                    <Icon size="lg" icon={faExclamationTriangle}>
                        No tenant found for user. This error indicates that
                        something has not been configured correctly.
                    </Icon>
                </Alert>
            </div>
        );
    }

    return <>{children}</>;
};
