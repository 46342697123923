import { SectionContent } from '@careerhub/content-shared';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoaderState } from '../../../models/error.models';
import { useTimer } from '../../hooks/timer.hook';
import { HtmlControl } from '../forms/HtmlControl';
import { sectionFormDelay } from './SectionFormContainer';

type SectionBody = Pick<SectionContent, 'body'>;

interface Props {
    defaultValues: SectionBody;
    onSubmit: (data: SectionBody) => void;
    state: LoaderState | undefined;
}

export const SectionBodyForm: React.FC<Props> = ({
    defaultValues,
    onSubmit,
    state,
}) => {
    const { triggerDelay } = useTimer(sectionFormDelay);

    const body = useMemo(
        () =>
            Array.isArray(defaultValues.body)
                ? defaultValues.body.join(',')
                : defaultValues.body,
        [defaultValues.body]
    );

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: { body },
    });
    const {
        handleSubmit,
        // formState: { isDirty, submitCount },
    } = formMethods;

    const onChange = useCallback(() => {
        triggerDelay(() => {
            handleSubmit(onSubmit)();
        });
    }, [handleSubmit, onSubmit, triggerDelay]);

    // const formControlClassName = useMemo(() => {
    //     return isDirty && submitCount > 0 && !isDelaying ? 'is-valid' : '';
    // }, [isDelaying, isDirty, submitCount]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <HtmlControl
                    name="body"
                    state={state}
                    onChange={onChange}
                    initialValue={body}
                />
                {/* <TinyEditor
                    name="body"
                    state={state}
                    onChange={onChange}
                    formControlClassName={formControlClassName}
                    initialValue={body}
                /> */}
            </form>
        </FormProvider>
    );
};
