import Ajv from 'ajv';
import { SiteContextMap } from '@careerhub/content-shared';
import schema from '../../../content.schema.json';

const LOCALSTORAGE_CONTENT_ID = 'cew:content:json';

const ajv = new Ajv({ allErrors: true });

export function get(): SiteContextMap | undefined {
    const localContent = localStorage.getItem(LOCALSTORAGE_CONTENT_ID);

    if (!localContent) {
        return undefined;
    }

    try {
        const parsed = JSON.parse(localContent);

        const validate = ajv.compile(schema);

        const valid = validate(parsed);

        return valid ? (parsed as SiteContextMap) : undefined;
    } catch (error) {
        console.error(error);
    }

    clear();
    return undefined;
}

export function save(content: SiteContextMap) {
    try {
        localStorage.setItem(LOCALSTORAGE_CONTENT_ID, JSON.stringify(content, null, 0));
    } catch (error) {
        console.error(error);
    }
}

export function clear() {
    localStorage.removeItem(LOCALSTORAGE_CONTENT_ID);
}
