import { SectionContent } from '@careerhub/content-shared';
import { useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

type LinkContent = SectionContent['link'];

interface Props {
    name: string;
    className?: string;
    onChange?: () => void;
    initialValue?: LinkContent;
}

export const LinkControl: React.FC<Props> = ({ name, className, onChange, initialValue }) => {
    const { register, setValue } = useFormContext();

    useEffect(() => {
        register(name);
    }, [name, register]);

    const innerMethods = useForm<Exclude<LinkContent, undefined>>({
        mode: 'onChange',
        defaultValues: initialValue,
    });

    const onChangeInner = () => {
        const innerValue = innerMethods.getValues();
        setValue(name, innerValue.url ? innerValue : undefined, {
            shouldValidate: true,
            shouldDirty: true,
        });

        if (onChange) {
            onChange();
        }
    };

    const urlValue = innerMethods.watch('url');
    const labelValue = innerMethods.watch('text');
    const innerSetValue = innerMethods.setValue;

    useEffect(() => {
        if (!urlValue && labelValue) {
            innerSetValue('text', undefined);
        }
    }, [innerSetValue, labelValue, urlValue]);

    return (
        <FormProvider {...innerMethods}>
            <FormGroup>
                <label>Link</label>
                <div className={`fake-form-control ${className}`}>
                    <div className="d-flex">
                        <div className="mr-2 line-height-input">
                            {/* ToDo: add labelFor ids */}
                            <label className="d-block mb-1">Url</label>
                            <label className="d-block mb-0">Text</label>
                        </div>
                        <div className="w-100">
                            <input
                                name="url"
                                className="form-control mb-1"
                                ref={innerMethods.register}
                                onChange={onChangeInner}
                            />

                            <input
                                name="text"
                                disabled={!urlValue}
                                className="form-control"
                                ref={innerMethods.register}
                                onChange={onChangeInner}
                            />
                        </div>
                    </div>
                </div>
            </FormGroup>
        </FormProvider>
    );
};
