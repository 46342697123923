import { SectionContent } from '@careerhub/content-shared';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTimer } from '../../hooks/timer.hook';
import { LoaderState } from '../../../models/error.models';
import { LinkControl } from '../forms/LinkControl';
import { sectionFormDelay } from './SectionFormContainer';

type SectionLink = Pick<SectionContent, 'link'>;
const PROP_NAME: keyof SectionLink = 'link';

interface Props {
    defaultValues?: SectionLink;
    onSubmit: (data: SectionLink) => void;
    state: LoaderState | undefined;
}

export const SectionLinkForm: React.FC<Props> = ({ defaultValues, onSubmit, state }) => {
    const { isDelaying, triggerDelay } = useTimer(sectionFormDelay);

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues,
    });
    const {
        handleSubmit,
        formState: { isDirty, submitCount, isValid },
    } = formMethods;

    const onChange = () => {
        triggerDelay(() => {
            handleSubmit(onSubmit)();
        });
    };

    const formClassName = useMemo(() => {
        if (!isValid) {
            return 'is-invalid';
        }

        if (state?.loading && state?.properties?.includes(PROP_NAME)) {
            return 'input-container-spinner is-waiting';
        }

        if (isDelaying || state?.loading || state?.error) {
            return '';
        }

        return isDirty && submitCount > 0 ? 'is-valid' : '';
    }, [isValid, isDelaying, state, isDirty, submitCount]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <LinkControl
                    name={PROP_NAME}
                    className={formClassName}
                    onChange={onChange}
                    initialValue={defaultValues?.link}
                />
            </form>
        </FormProvider>
    );
};
