import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { LoaderState } from '../../../models/error.models';
import { sectionImageUploadAsync } from '../../../logic/features/sections/section.actions';
import { Icon } from '../shared/Icon';

const ACCEPTED_TYPES = ['.gif', '.jpg', '.jpeg', '.png'];

interface Props {
    sectionId: string;
    state: LoaderState | undefined;
}

export const SectionImageDropzone: React.FC<Props> = ({ sectionId, state }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState<{ file?: File; rejection?: FileRejection } | undefined>(
        undefined
    );

    const onDrop = useCallback(
        (newAccepted: File[], newRejected: FileRejection[]) => {
            setFile({
                file: newAccepted[0],
                rejection: newRejected[0],
            });
        },
        [setFile]
    );

    useEffect(() => {
        if (file?.file) {
            dispatch(
                sectionImageUploadAsync.request({
                    file: file.file,
                    sectionId: sectionId,
                })
            );
        }
    }, [dispatch, file, sectionId]);

    // 4194304 = 4mbs
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: 4194304,
        accept: ACCEPTED_TYPES,
        multiple: false,
    });
    <small>
        <div>Accepted file types: gif, jpg, jpeg, png</div>
        <div>Max size: 4mbs</div>
    </small>;

    const spinnerClassName = useMemo(() => {
        return `input-container-spinner${state?.loading ? ' is-waiting' : ''}`;
    }, [state]);

    return (
        <div className="fake-form-control mb-2">
            <div className="d-flex">
                <div className="mr-2 line-height-input">File</div>
                <div className="w-100">
                    <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className={spinnerClassName}>
                            <div
                                className={`dropzone-container fake-form-control text-muted${
                                    isDragActive ? ' drag-hover' : ''
                                }`}
                            >
                                Click or drag files
                            </div>
                        </div>
                    </div>

                    {file?.rejection?.errors.length ? (
                        <span className="invalid-feedback d-block">
                            <Icon icon={faExclamationTriangle} size="sm">
                                {file?.rejection?.errors.map(e => e.message).join(', ')}
                            </Icon>
                        </span>
                    ) : (
                        <small className="text-muted">
                            Accepted file types: gif, jpg, jpeg, png. Max size: 4mbs
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};
