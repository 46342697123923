import RedactorX from '@visualeyes/redactor-x';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoaderState } from '../../../models/error.models';
import { FormGroup } from 'react-bootstrap';

// IMPORTANT NOTE: This is just copied code from the employer site
// The goal is to refactor this code away into a shared form library
// but I am not there yet. And I had to replace the previous TinyMCE editor
// with redactor-x. TineMCE has updated their pricing and it doesn't work for us.

interface Props {
    name: string;
    onChange?: () => void;
    initialValue?: string;
    formControlClassName?: string;
    state: LoaderState | undefined;
}

export const HtmlControl = (props: Props) => {
    const { name, onChange, initialValue } = props;

    const [loaded, setLoaded] = useState(false);
    const ref = useRef<any>(null);

    const { register, getValues, setValue } = useFormContext();
    const editorChange = useCallback(
        e => {
            setValue(name, e?.params?.html, {
                shouldDirty: true,
                shouldValidate: true,
            });

            onChange?.();
        },
        [name, onChange, setValue]
    );

    useEffect(() => {
        if (!RedactorX || loaded) {
            return;
        }
        ref.current = RedactorX(`[name=html-${name}]`, {
            // events
            subscribe: {
                'editor.change': editorChange,
                // 'editor.before.paste': editorBeforePaste,
            },

            // extremely specific paste tags
            paste: {
                images: false,
                formTags: [],
                // blockTags: ['p'],
                // inlineTags: [],
                // blockTags: allowedBlockTags,
                // inlineTags: allowedInlineTags,
            },
            // // disabling almost all settings
            source: false,
            addbar: false,
            topbar: false,
            toolbar: {
                sticky: false,
                hide: ['deleted'],
            },
            buttons: {
                // my god this was hard to figure out, just to stop the
                // stupid buttons from disappearing !!
                editor: [],
                drop: false,
            },
            table: false,
            embed: false,
            image: false,
            pre: false,
            quote: false,
            line: false,
        });

        // set initial value if it exists
        // const initialValue = getValues(name);
        // console.log('initialValue', initialValue, ref.current.editor);
        if (initialValue) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            ref.current.editor.setContent({ html: initialValue });
        }

        setLoaded(true);
    }, [editorChange, loaded, name, getValues, initialValue]);

    useEffect(() => {
        return () => {
            if (ref.current) {
                // I'm not sure what this does, but in the examples
                // they mention that this should be done if you are
                // using a routing engine, which I definitely am
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                (ref.current as unknown as any).destroy?.();
                setLoaded(false);
            }
        };
    }, []);

    useEffect(() => {
        register(name);
    }, [name, register]);

    return (
        <FormGroup>
            <label>Body</label>
            <div>
                <textarea name={`html-${name}`}></textarea>
            </div>
        </FormGroup>
    );
};
