import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { SectionContent } from '@careerhub/content-shared';
import isEqual from 'lodash/isEqual';
import { useCallback, useContext, useMemo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { useTimer } from '../../hooks/timer.hook';
import {
    sectionDeleteAsync,
    sectionSetHover,
} from '../../../logic/features/sections/section.actions';
import { routePathCreator } from '../routes/RouteList';
import { DeletePopover } from '../shared/DeletePopover';
import { CustomDragContext } from '../shared/DragWrapper';
import { Icon } from '../shared/Icon';
import { SectionFormContainer } from './SectionFormContainer';

interface Props {
    section: SectionContent;
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const SectionListItem: React.FC<Props> = ({ section, dragHandleProps }) => {
    const { activeKey, tenant } = useRootSelector(state => state.content);
    const collectionId = useRootSelector(state => state.collections.activeId);
    const { viewMode } = useRootSelector(state => state.layout);
    const { droppableIsDraggingOverWith: droppableIsDraggingOverwith } =
        useContext(CustomDragContext);

    const { hoverSectionId, activeSectionId } = useRootSelector(state => state.sections);

    const { triggerDelay } = useTimer(400);
    const isOpen = useMemo(() => activeSectionId === section.id, [activeSectionId, section.id]);

    const dispatch = useDispatch();
    const onDelete = () => {
        dispatch(sectionDeleteAsync.request({ id: section.id }));
    };

    const toggleOpen = () => {
        const path = routePathCreator.content({
            contentKey: activeKey,
            tenant,
            collectionId,
            sectionId: activeSectionId === section.id ? undefined : section.id,
        });
        dispatch(push(path));
    };

    const onMouseEnter = useCallback(() => {
        if (viewMode !== 'all') {
            return;
        }

        triggerDelay(() => {
            if (droppableIsDraggingOverwith) {
                return;
            }

            if (section.id === hoverSectionId) {
                return;
            }

            dispatch(sectionSetHover({ id: section.id }));
        });
    }, [viewMode, triggerDelay, droppableIsDraggingOverwith, section.id, hoverSectionId, dispatch]);

    const onMouseLeave = useCallback(() => {
        if (viewMode !== 'all') {
            return;
        }

        triggerDelay(() => {
            if (droppableIsDraggingOverwith) {
                return;
            }

            if (isEqual(undefined, hoverSectionId)) {
                return;
            }

            dispatch(sectionSetHover({ id: undefined }));
        });
    }, [dispatch, droppableIsDraggingOverwith, hoverSectionId, triggerDelay, viewMode]);

    if (!section) {
        return null;
    }

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`section-list-item-container ${isOpen ? 'active' : ''}`}
        >
            <div className="d-flex w-100 list-group-item-action-fake border-0 section-list-item">
                <div className="pl-3 pr-2 py-3" {...dragHandleProps}>
                    <Icon className="fa-w-14" icon={faGripVertical} />
                </div>
                <button className="list-group-item-inner-button w-100" onClick={() => toggleOpen()}>
                    {section.header}
                </button>
                <div>
                    <DeletePopover
                        overlayKey={`delete-section-${section.id}`}
                        linkClassName="pl-2 pr-3 py-3"
                        label="Are you sure?"
                        onDelete={onDelete}
                    >
                        <div>
                            <p>This action will delete this section</p>
                        </div>
                    </DeletePopover>
                </div>
            </div>
            {isOpen && (
                <div className="px-3 pt-3">
                    <SectionFormContainer section={section} />
                </div>
            )}
        </div>
    );
};
