import { ALL_CONTENT_KEYS, ContentKey } from '@careerhub/content-shared';
import jwt_decode from 'jwt-decode';

export const decodeContentKeys = (
    token: string
): { siteAccess: ContentKey[]; ch_key: string } => {
    const decoded = jwt_decode<{
        ch_permission_Content?: string[];
        ch_key: string;
    }>(token);

    const lower =
        decoded.ch_permission_Content?.map(permission =>
            permission.toLowerCase()
        ) || [];

    const siteAccess = lower.includes('all')
        ? [...ALL_CONTENT_KEYS]
        : ALL_CONTENT_KEYS.filter(key => lower.includes(key.toLowerCase()));

    const ch_key = decoded.ch_key;

    return {
        siteAccess,
        ch_key,
    };
};
