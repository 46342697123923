import { ContentKey } from '@careerhub/content-shared';
import { Route, Switch } from 'react-router-dom';
import { ContentPage } from '../pages/ContentPage';
import { NotFoundPage } from '../pages/NotFoundPage';
import { PublishPage } from '../pages/PublishPage';
import { ResourcePage } from '../pages/ResourcePage';
import { AuthenticationGuard } from './AuthenticationGuard';

export interface TenantParams {
    tenant: string;
}

export interface ContentPageParams extends TenantParams {
    contentKey: ContentKey;
    collectionId?: string;
    sectionId?: string;
}

export const routePaths = {
    home: '/',
    tenant: '/:tenant',
    site: '/:tenant/:contentKey',
    siteContent: '/:tenant/:contentKey/content',
    contentCollection: '/:tenant/:contentKey/content/:collectionId',
    contentCollectionSection: '/:tenant/:contentKey/content/:collectionId/:sectionId',
    sitePublish: '/:tenant/:contentKey/publish',
    resources: '/:tenant/:contentKey/resources',
    history: '/:tenant/:contentKey/history',
    historyItem: '/:tenant/:contentKey/history/:id',
};

export const routePathCreator = {
    content: (params: ContentPageParams) =>
        '/' +
        [
            params.tenant,
            params.contentKey,
            'content',
            params.collectionId,
            params.collectionId && params.sectionId,
        ]
            .filter(i => !!i)
            .join('/'),
    publish: (params: ContentPageParams) => `/${params.tenant}/${params.contentKey}/publish`,
    history: (params: ContentPageParams, id?: string) =>
        `/${params.tenant}/${params.contentKey}/history${id ? '/' + id : ''}`,
};

export const RouteList = () => {
    return (
        <Switch>
            <AuthenticationGuard>
                <Route
                    path={[
                        routePaths.siteContent,
                        routePaths.contentCollection,
                        routePaths.contentCollectionSection,
                    ]}
                    component={ContentPage}
                />
                <Route path={routePaths.sitePublish} component={PublishPage} />
                <Route path={routePaths.resources} component={ResourcePage} />
            </AuthenticationGuard>

            <Route component={NotFoundPage} />
        </Switch>
    );
};
