import { faPencilAlt, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useRootSelector } from '../../hooks/root-selector.hook';
import {
    collectionDeleteAsync,
    collectionModeSetActive,
} from '../../../logic/features/collections/collection.actions';
import { componentCreateAsync } from '../../../logic/features/components/component.actions';
import { DeleteModal } from '../modals/DeleteModal';
import { Icon } from '../shared/Icon';

export const CollectionDropdown = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useDispatch();
    const activeCollectionId = useRootSelector(state => state.collections.activeId);

    const onAddVariant = () => {
        dispatch(
            componentCreateAsync.request({
                collectionId: activeCollectionId!,
                variant: 'alternate',
            })
        );
    };

    const onEdit = () => {
        dispatch(collectionModeSetActive({ mode: 'edit' }));
    };

    const onDelete = useCallback(() => {
        setShowConfirm(true);
    }, []);
    const onDeleteConfirm = useCallback(() => {
        if (!activeCollectionId) {
            // should never get hit
            return;
        }
        // dispatch(collectionDelete());
        dispatch(collectionDeleteAsync.request({ id: activeCollectionId }));
        setShowConfirm(false);
    }, [activeCollectionId, dispatch]);

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    variant="primary"
                    size="sm"
                    id="collection-dropdown"
                    className="ml-3"
                >
                    Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onEdit}>
                        <Icon icon={faPencilAlt}>Edit</Icon>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onAddVariant}>
                        <Icon icon={faPlus}>Add component</Icon>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={onDelete}>
                        <Icon icon={faTimes}>Delete</Icon>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {showConfirm && (
                <DeleteModal onConfirm={onDeleteConfirm} onCancel={() => setShowConfirm(false)} />
            )}
        </>
    );
};
