import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { collectionUpdateAsync } from '../../../logic/features/collections/collection.actions';
import { reorder } from '../../../logic/helpers/array.helper';
import { useCloneList } from '../../hooks/clone-list.hook';
import { useComponents } from '../../hooks/content.hook';
import { useRootSelector } from '../../hooks/root-selector.hook';
import { DragWrapper, DragWrapperDropResult } from '../shared/DragWrapper';
import { ComponentListAdd } from './ComponentListAdd';
import { ComponentTabs } from './ComponentTabs';

export const ComponentList = () => {
    const activeCollectionId = useRootSelector(
        state => state.collections.activeId
    );
    const activeComponentList = useComponents();
    const activeComponentIds = useMemo(
        () => activeComponentList.map(v => v.id),
        [activeComponentList]
    );
    const dispatch = useDispatch();

    const [cloneList, setCloneList] = useCloneList(activeComponentList);

    const onDragEnd = (result: DragWrapperDropResult) => {
        if (!activeCollectionId) {
            // should never be hit
            return;
        }

        if (!result.destination) {
            return;
        }

        const toSave = reorder(
            activeComponentIds,
            result.source.index,
            result.destination.index
        );
        dispatch(
            collectionUpdateAsync.request({
                id: activeCollectionId,
                componentPriority: toSave,
            })
        );
        setCloneList(cloneList =>
            toSave.map(id => cloneList.find(c => c.id === id)!)
        );
    };

    if (!activeCollectionId) {
        return null;
    }

    return (
        <>
            <DragWrapper droppableId="component-list" onDragEnd={onDragEnd}>
                {cloneList.map((component, index) => (
                    <DragWrapper.Inner
                        draggableId={`component-list-${component.id}`}
                        index={index}
                        key={`component-list-${component.id}`}
                        itemClassName="mb-3"
                    >
                        {provided => (
                            <ComponentTabs
                                componentId={component.id}
                                dragHandleProps={
                                    provided.dragHandleProps || undefined
                                }
                            />
                        )}
                    </DragWrapper.Inner>
                ))}
            </DragWrapper>

            <ComponentListAdd />
        </>
    );
};
